import { motion } from 'framer-motion'
import React from 'react'

import LoadingIndicator from './LoadingIndicator'

export interface ButtonProps {
  onClick?: (e: any) => void
  label: string | React.ReactNode
  type:
    | 'primary'
    | 'primary-outline'
    | 'secondary'
    | 'secondary-outline'
    | 'tertiary'
    | 'tertiary-outline'
    | 'dark'
    | 'success'
    | 'dark-link'
  htmlType?: 'submit' | 'reset' | 'button'
  useDefaultTextSize?: boolean
  loading?: boolean
  disabled?: boolean
  className?: string
  id?: string
}

const classes: Record<ButtonProps['type'], string> = {
  primary: 'bg-primary hover:bg-tertiary text-white font-semibold',
  success: 'bg-success hover:bg-success-75 text-white font-semibold',
  'primary-outline': 'bg-white hover:bg-primary hover:text-white text-primary border border-primary font-semibold',
  secondary: 'bg-white hover:bg-tertiary text-primary hover:text-white font-semibold',
  'secondary-outline':
    'bg-primary hover:bg-white border border-white hover:border-primary text-white hover:text-primary font-semibold',
  tertiary: 'bg-black hover:bg-dark text-white font-semibold',
  'tertiary-outline': 'bg-white hover:bg-black text-black hover:text-white border border-black font-semibold',
  dark: 'bg-dark hover:bg-dark-75 text-white font-semibold',
  'dark-link': 'bg-transparent text-black-high text-white font-semibold',
}

const Button = ({ useDefaultTextSize = true, ...props }: ButtonProps) => {
  const { type, htmlType, disabled, loading, ...restProps } = props

  const baseClassName = `${classes[type]} rounded ${loading ? 'flex justify-center items-center' : ''} ${
    disabled ? 'button-disabled' : ''
  }`

  const className = `${useDefaultTextSize ? 'text-md' : ''} ${baseClassName} ${
    restProps.className || 'w-full py-2 px-4'
  }`

  return (
    <motion.button
      type={htmlType}
      whileTap={disabled ? {} : { scale: 0.95 }}
      {...restProps}
      className={className}
      style={{ outline: 'none' }}
      onClick={props.onClick}
    >
      {loading ? <LoadingIndicator /> : null}
      {loading ? '' : props.label}
    </motion.button>
  )
}

export default Button
