import React from 'react'

import { CredentialsToBeModified } from '../../templates/MyAccount/components/SecurityChangeConfirmation'
import Button from '../atoms/Button'
import Alert, { AlertProps } from '../molecules/Alert'

interface ConfirmationAlertFooterContentProps {
  onConfirm: () => void
  confirmButtonLabel: string
  confirmButtonLoading?: boolean
  onCancel: () => void
  cancelButtonLabel: string
  cancelButtonClassname?: string
  confirmButtonClassname?: string
  confirmButtonDisabled?: boolean
  cancelButtonDisabled?: boolean
  useDefaultButtonTextSize?: boolean
  hasDivider?: boolean
  imageUrl?: string
  credentialsToBeModified?: CredentialsToBeModified | undefined
}

export interface ConfirmationAlertProps
  extends Omit<AlertProps, 'footerContent'>,
    ConfirmationAlertFooterContentProps {}

const FooterContent = ({
  onConfirm,
  confirmButtonLabel,
  onCancel,
  cancelButtonLabel,
  confirmButtonLoading,
  confirmButtonClassname,
  cancelButtonClassname,
  confirmButtonDisabled,
  cancelButtonDisabled,
  useDefaultButtonTextSize = true,
}: ConfirmationAlertFooterContentProps) => (
  <div className="flex flex-col">
    <Button
      label={confirmButtonLabel}
      type="primary"
      className={confirmButtonClassname ?? 'shadow-none h-10 mb-2'}
      useDefaultTextSize={useDefaultButtonTextSize}
      loading={confirmButtonLoading}
      disabled={confirmButtonDisabled}
      onClick={!confirmButtonLoading ? onConfirm : undefined}
    />
    <Button
      label={cancelButtonLabel}
      type="primary-outline"
      useDefaultTextSize={useDefaultButtonTextSize}
      className={cancelButtonClassname ?? 'shadow-none h-10'}
      disabled={cancelButtonDisabled}
      onClick={() => (!cancelButtonDisabled ? onCancel() : null)}
    />
  </div>
)

const ConfirmationAlert = ({ hasDivider, children, imageUrl, ...restProps }: ConfirmationAlertProps) => {
  const footerContent = <FooterContent {...restProps} />
  return (
    <Alert {...restProps} footerContent={footerContent}>
      <div className="flex w-full">
        {!!imageUrl && (
          <div className="w-1/4 flex justify-center items-center">
            <img src={imageUrl} />
          </div>
        )}
        <div className={imageUrl ? 'w-3/4 pl-4' : ''}>{children ?? null}</div>
      </div>
      {hasDivider && <div className="border-b-2 border-medium mt-4 mb-2" />}
    </Alert>
  )
}

export default ConfirmationAlert
