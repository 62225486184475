import { AuthState } from './context'

type SIGN_IN_BEGINNING = { type: 'SIGN_IN_BEGINNING' }
type SIGN_IN_FINISHED = { type: 'SIGN_IN_FINISHED' }
type SIGN_IN = { type: 'SIGN_IN'; needPasswordUpdate: boolean }
type SIGN_UP = {
  type: 'SIGN_UP'
  newUserData: {
    dni?: string
    name: string
    phone: string
    defaultStoreId: number
    dutyFreeStores?: number[]
  }
}
type SIGN_OUT = { type: 'SIGN_OUT' }
type SET_USER = {
  type: 'SET_USER'
  name: string
  dni: string
  defaultStoreId: string
  email: string
  id: string
  phone: string
  canEditStoreData: boolean
  canEditUserData: boolean
  canViewCredit: boolean
  test: boolean
  onboarding: {
    mandatoryModal: boolean
    products: boolean
    cart: boolean
    checkout: boolean
  }
  visibleStores: number[]
  dutyFreeStores?: number[]
  inviteeRequestId?: string
  origin?: string | undefined
  referenceType: string
}
type RESET = { type: 'RESET' }
type CHANGE_DEFAULT_STORE_ID = { type: 'CHANGE_DEFAULT_STORE_ID'; newDefaultStoreId: AuthState['defaultStoreId'] }
type CHANGE_AUTH_STATE = { type: 'CHANGE_AUTH_STATE'; newAuthState: Partial<AuthState> }
type UPDATE_ONBOARDING = {
  type: 'UPDATE_ONBOARDING'
  onboarding: {
    mandatoryModal: boolean
    products: boolean
    cart: boolean
    checkout: boolean
  }
}

export type AuthActions =
  | SIGN_IN_BEGINNING
  | SIGN_IN_FINISHED
  | SIGN_IN
  | SET_USER
  | SIGN_OUT
  | RESET
  | SIGN_UP
  | CHANGE_DEFAULT_STORE_ID
  | UPDATE_ONBOARDING
  | CHANGE_AUTH_STATE

function reducer(state: AuthState, action: AuthActions): AuthState {
  switch (action.type) {
    case 'SIGN_IN_BEGINNING': {
      return { ...state, signInLoading: true }
    }
    case 'SIGN_IN_FINISHED': {
      return { ...state, signInLoading: false }
    }
    case 'SIGN_IN':
      return {
        ...state,
        isAuth: true,
        needPasswordUpdate: action.needPasswordUpdate,
      }
    case 'SIGN_UP':
      return {
        ...state,
        dni: action.newUserData.dni ?? state.dni,
        name: action.newUserData.name,
        phone: action.newUserData.phone,
        defaultStoreId: `${action.newUserData.defaultStoreId}`,
        dutyFreeStores: action.newUserData.dutyFreeStores ?? [],
        needPasswordUpdate: false,
        isAuth: true,
      }
    case 'SET_USER':
      return {
        ...state,
        dni: action.dni,
        email: action.email,
        name: action.name,
        defaultStoreId: action.defaultStoreId,
        id: action.id,
        phone: action.phone,
        canEditStoreData: action.canEditStoreData,
        canEditUserData: action.canEditUserData,
        canViewCredit: action.canViewCredit,
        test: action.test,
        onboarding: action.onboarding,
        visibleStores: action.visibleStores,
        dutyFreeStores: action.dutyFreeStores ?? [],
        inviteeRequestId: action.inviteeRequestId,
        origin: action.origin ?? '',
        referenceType: action.referenceType,
      }
    case 'UPDATE_ONBOARDING':
      return {
        ...state,
        onboarding: action.onboarding,
      }
    case 'SIGN_OUT':
      return AuthState
    case 'RESET':
      return AuthState
    case 'CHANGE_DEFAULT_STORE_ID':
      return { ...state, defaultStoreId: action.newDefaultStoreId }
    case 'CHANGE_AUTH_STATE':
      return { ...state, ...action.newAuthState }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
