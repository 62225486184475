import React, { ReactNode } from 'react'

import { Item } from '../organisms/MenuContent/types'

interface ItemProp {
  isActive: boolean
  isLast: boolean
  item: Item
  badge?: ReactNode
  isSmall: boolean
}

function ItemMenu({ item, isActive, isLast, badge, isSmall }: ItemProp) {
  return (
    <div
      className={`w-full flex justify-between items-center px-4 cursor-pointer ${
        isActive ? 'bg-medium-50' : ''
      } hover:bg-medium-50 text-black}
      ${isSmall ? 'h-10 py-2' : 'py-3'}
      `}
      onClick={item.onClick}
    >
      <div className={`flex items-center ${isLast ? 'font-bold text-black-med' : ''}`}>
        {item.icon}
        <span className={`truncate ${isLast ? 'text-xs' : ''}`}>{item.label}</span>
      </div>
      {badge}
    </div>
  )
}

export default ItemMenu
