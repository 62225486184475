import React, { Ref, TextareaHTMLAttributes, useEffect, useState } from 'react'

import { randomString } from '../../utils/strings'
import FieldFeedback from './FieldFeedback'

type ResizeValues = 'only-x' | 'only-y' | 'none' | 'normal'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean
  placeholder?: string
  resize?: ResizeValues
  label?: string
  autoHeight?: boolean
  showMaxLenghtCounter?: boolean
  message?: string
  error?: boolean
  name: string
  ref?: any
}

const TextArea = React.forwardRef((props: TextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
  const {
    placeholder,
    label,
    message,
    error,
    name,
    resize,
    id,
    autoHeight,
    value,
    maxLength,
    className,
    onChange,
    showMaxLenghtCounter = true,
    ...textAreaProps
  } = props
  const [isFocus, setIsFocus] = useState(false)
  const [textLength, setTextLength] = useState<number>(0)

  const UNIQUE_ID = id || `textarea__${randomString(8)}`

  const increaseHeight = () => {
    if (autoHeight) {
      const element = document.querySelector(`#${UNIQUE_ID}`)
      element?.setAttribute('style', `height: auto;${element?.getAttribute('style')}`)
      element?.setAttribute('style', `height: ${element.scrollHeight}px;${element?.getAttribute('style')}`)
    }
  }

  useEffect(() => {
    increaseHeight()
  })

  const renderResizeClass = (value: ResizeValues) => {
    switch (value) {
      case 'only-x':
        return 'resize-x'
      case 'only-y':
        return 'resize-y'
      case 'none':
        return 'resize-none'
      default:
        return ''
    }
  }

  return (
    <div className="min-w-full relative">
      {(label || (maxLength && showMaxLenghtCounter)) && (
        <div className="min-h-6">
          {label && <label className="left text-sm">{label}</label>}
          {maxLength && showMaxLenghtCounter && (
            <p className="absolute right-1 top-0 text-dark-50 text-sm">{maxLength - textLength} caracteres</p>
          )}
        </div>
      )}
      <div className="relative">
        <textarea
          {...textAreaProps}
          onChange={(e) => {
            setTextLength(e.currentTarget.value.length)
            onChange?.(e)
          }}
          name={name}
          ref={ref}
          id={UNIQUE_ID}
          maxLength={maxLength}
          placeholder={isFocus ? '' : placeholder}
          value={value}
          className={`base-input ${renderResizeClass(resize ?? 'normal')} ${autoHeight ? 'overflow-hidden' : ''} ${
            className ?? ''
          }`}
          onFocus={(e) => {
            setIsFocus(true)
            if (textAreaProps.onFocus) textAreaProps.onFocus(e)
          }}
          onBlur={(e) => {
            setIsFocus(false)
            if (textAreaProps.onBlur) textAreaProps.onBlur(e)
          }}
        ></textarea>
      </div>
      {message ? <FieldFeedback error={error} message={message} /> : null}
    </div>
  )
})

export default TextArea
