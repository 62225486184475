import React from 'react'

import Overlay from '../Overlay'
import AlertCard, { AlertCardProps } from './AlertCard'

const overlayClasses = 'flex justify-center items-center pt-0 tablet:items-start tablet:pt-52'

export interface AlertProps extends AlertCardProps {
  isActive?: boolean
  overlayClassName?: string
  closeOnBlur?: boolean
}

const Alert = ({ isActive, overlayClassName, onClose, ...restProps }: AlertProps) => (
  <Overlay
    zIndex={50}
    isActive={isActive ?? false}
    className={`${overlayClasses} ${overlayClassName ?? ''}`}
    hideScrollbars
    onOutsideClick={() => (onClose ? onClose() : null)}
    isFixed
  >
    <AlertCard {...restProps} onClose={onClose} />
  </Overlay>
)

export default Alert
