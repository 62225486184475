import { navigate } from 'gatsby'
import React, { useState } from 'react'

import { useByCountry } from '../../../i18n'
import { ShortStore } from '../../../types'
import { ConfirmationAlertProps } from '../ConfirmationAlert'

export enum ChangeStoreAlertType {
  ACCOUNT,
  PRODUCTS,
}

const REDIRECTION_ROUTES = {
  '/app/my-account/orders/([0-9]+)': '/app/my-account/orders',
  '/app/my-account/(invoices|credit-notes)/([0-9]+)': '/app/my-account/invoices',
}

export const useChangeStoreConfimation = () => {
  const [state, setState] = useState<ConfirmationAlertProps>({
    isActive: false,
    footerClassName: 'mx-6 desktop:mx-6',
    confirmButtonClassname: 'text-sm h-8 mb-4 desktop:h-10  desktop:mb-4 desktop:text-md',
    cancelButtonClassname: 'text-sm h-8 desktop:h-10 desktop:text-md',
    useDefaultButtonTextSize: false,
    onCancel: () => setState({ ...state, isActive: false, onConfirm: () => null }),
    onClose: () => setState({ ...state, isActive: false, onConfirm: () => null }),
    onConfirm: () => null,
    confirmButtonLabel: 'Cambiar local',
    cancelButtonLabel: 'Mantener',
  })

  const renderMessage = (store: ShortStore, alertType: ChangeStoreAlertType) => {
    if (alertType === ChangeStoreAlertType.ACCOUNT) {
      return (
        <div className="text-sm mb-3 mt-1">
          <p className="mb-5">
            Te cambiarás al local <span className="font-black">{store.label}</span> con{' '}
            <span className="font-black">ID {store.id}</span>.
          </p>
          <p>
            Al cambiar, verás la información y los días de {useByCountry('despacho', 'entrega')} correspondientes del
            local seleccionado.
          </p>
        </div>
      )
    }
    return (
      <div className="text-sm mb-3 mt-1">
        <p className="mb-5">
          Te cambiarás al local <span className="font-black">{store.label}</span> con{' '}
          <span className="font-black">ID {store.id}</span>.
        </p>
        <p>Al cambiar, mantendremos los productos de tu carro de compras en la página de tu local actual.</p>
      </div>
    )
  }

  const triggerStoreConfirmationAlert = (
    store: ShortStore,
    alertType: ChangeStoreAlertType,
    onConfirm: () => Promise<void> | void,
  ) => {
    setState({
      ...state,
      isActive: true,
      onConfirm: async () => {
        setState({ ...state, isActive: true, confirmButtonLoading: true, children: renderMessage(store, alertType) })
        await onConfirm()
        const matchRoute = Object.keys(REDIRECTION_ROUTES).find((route) => {
          const routeRegexp = new RegExp(route)
          return routeRegexp.test(location.pathname)
        })
        setState({ ...state, isActive: false, confirmButtonLoading: false, onConfirm: () => null })
        if (matchRoute) await navigate(REDIRECTION_ROUTES[matchRoute])
      },
      children: renderMessage(store, alertType),
    })
  }

  return {
    state,
    triggerStoreConfirmationAlert,
  }
}
