import { createContext } from 'react'

import { AppActions } from './reducer'
import { AppState } from './types'

export const AppDefaultState = {
  isLoadingStores: false,
  isLoadingCurrentStore: false,
  stores: [],
  currentStore: undefined,
  defaultProductImage: '',
  selectedStoreId: '',
  companyLogo: '',
  companyLogoColorful: '',
  companyPhoneNumber: '',
  companyEmailAddress: '',
  welcomeVideo: undefined,
  lastStoresUpdate: 0,
  productsUnitsLimits: [],
  loginBackgroundImageUrl: '',
  loginLogo: '',
  onboardingRun: false,
  onboardingStep: 0,
}

export const AppContext = createContext<AppState>(AppDefaultState)
export type AppDispatch = (action: AppActions) => void
export const DispatchContext = createContext<AppDispatch | undefined>(undefined)
