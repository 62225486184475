import {
  addStoreFavoriteProduct,
  addStoreMultipleFavoriteProduct,
  deleteStoreFavoriteProduct,
  getDefaultStore,
  getUserStores,
} from '../../services/user'
import { FavoriteProduct } from '../../types'
import { getMaxUnitsByCategory } from '../../utils/constants'
import { AppDispatch } from './context'
import { AppState } from './types'

export function fetchStores(dispatch: AppDispatch) {
  return async (storeIds: number[], defaultStoreId: string) => {
    try {
      dispatch({ type: 'FETCH_STORES_BEGINNING' })
      const { stores }: { stores: AppState['stores'] } = await getUserStores({ storeIds, defaultStoreId })

      dispatch({
        type: 'SET_STORES',
        stores,
      })
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      dispatch({ type: 'FETCH_STORES_FINISHED' })
    }
  }
}

export function fetchDefaultStore(dispatch: AppDispatch) {
  return async (defaultStoreId: string, dutyFreeStores?: number[]) => {
    try {
      dispatch({ type: 'FETCH_DEFAULT_STORE_BEGINNING' })
      const { store }: { store: AppState['currentStore'] } = await getDefaultStore({
        id: defaultStoreId,
        dutyFreeStores,
      })

      dispatch({ type: 'SET_CURRENT_STORE', currentStore: store })
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      dispatch({ type: 'FETCH_DEFAULT_STORE_FINISHED' })
    }
  }
}

export function changeStore(dispatch: AppDispatch) {
  return async (currentStoreId: string, dutyFreeStores?: number[]) => {
    try {
      dispatch({ type: 'FETCH_DEFAULT_STORE_BEGINNING' })
      const { store }: { store: AppState['currentStore'] } = await getDefaultStore({
        id: currentStoreId,
        dutyFreeStores,
      })

      dispatch({ type: 'SET_CURRENT_STORE', currentStore: store })
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      dispatch({ type: 'FETCH_DEFAULT_STORE_FINISHED' })
    }
  }
}

export function clearStoresData(dispatch: AppDispatch) {
  return () => dispatch({ type: 'CLEAR_STORES_DATA' })
}

export function updateCurrentStoreData(dispatch: AppDispatch) {
  return (newData: Partial<AppState['currentStore']>) =>
    dispatch({ type: 'UPDATE_CURRENT_STORE_DATA', newCurrentStoreData: newData })
}

export function addFavoriteProduct(dispatch: AppDispatch) {
  return async (defaultStoreId: string, product: FavoriteProduct) => {
    try {
      const { store, newFavorite }: { store: number; newFavorite: FavoriteProduct } = await addStoreFavoriteProduct({
        id: +defaultStoreId,
        product,
      })

      dispatch({ type: 'ADD_STORE_FAVORITE_PRODUCT', currentStoreId: `${store}`, newFavoriteProduct: newFavorite })
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export function addMultipleFavoriteProduct(dispatch: AppDispatch) {
  return async (defaultStoreId: string, products: FavoriteProduct[]) => {
    try {
      const { store, newFavorites }: { store: number; newFavorites: number[] } = await addStoreMultipleFavoriteProduct({
        id: +defaultStoreId,
        products: products.map((product) => product.productId),
      })

      dispatch({
        type: 'ADD_STORE_MULTIPLE_FAVORITE_PRODUCT',
        currentStoreId: `${store}`,
        newFavoriteProducts: newFavorites.map((product) => ({
          productId: product,
          name: '',
        })),
      })
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export function deleteFavoriteProduct(dispatch: AppDispatch) {
  return async (defaultStoreId: string, productId: number) => {
    try {
      const { store, deletedFavorite }: { store: number; deletedFavorite: FavoriteProduct } =
        await deleteStoreFavoriteProduct({ id: +defaultStoreId, productId })

      dispatch({
        type: 'DELETE_STORE_FAVORITE_PRODUCT',
        currentStoreId: `${store}`,
        deletedFavoriteProduct: deletedFavorite,
      })
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export const getProductUnitsLimits = (state: AppState) => (sku: number, category: string) => {
  const { productsUnitsLimits } = state

  const defaultLimit = productsUnitsLimits.find((limit) => limit.sku === 'default') || {
    sku: 'default',
    minimumUnits: 1,
    maximumUnits: 99,
    unitsMultiple: 1,
  }
  const productLimit = productsUnitsLimits.find((limit) => limit.sku === `${sku}`)
  const productUnitsLimits = productLimit || { ...defaultLimit }

  const maxUnitsByCategory = getMaxUnitsByCategory(category)
  if (maxUnitsByCategory) {
    productUnitsLimits.maximumUnits = maxUnitsByCategory
  }
  return productUnitsLimits
}

export function setOnboardingRun(dispatch: AppDispatch) {
  return (onboardingRun: boolean) => dispatch({ type: 'SET_ONBOARDING_RUN', onboardingRun })
}

export function setOnboardingStep(dispatch: AppDispatch) {
  return (onboardingStep: number) => dispatch({ type: 'SET_ONBOARDING_STEP', onboardingStep })
}
