import jwtDecode from 'jwt-decode'

import { persistCredentials } from '../../contexts/Auth/actions'
import { AuthState } from '../../contexts/Auth/context'
import { loadState } from '../../utils/localstorage'
import { authKeys, getAccessToken, getAccessTokenExpiration, getRefreshToken } from '../../utils/store'
import { signOutRedirect } from '../auth'
import { getNewAccessToken, retryRequest } from './authentication'

export function renewTokens() {
  return async (error) => {
    const accessTokenExpiration = getAccessTokenExpiration()
    const isTokenExpired = !accessTokenExpiration || Date.now() > accessTokenExpiration
    const refreshToken = getRefreshToken()

    if (error.response?.status === 401) {
      if (!isTokenExpired) {
        return signOutRedirect()
      }
      try {
        const {
          data: { token: newAccessToken },
        } = await getNewAccessToken(refreshToken)

        const tokenDecoded = jwtDecode<{
          exp: number
          metadata: { defaultStore: number; visibleStores?: number[]; dutyFreeStores?: number[] }
        }>(newAccessToken)

        persistCredentials({
          accessToken: newAccessToken,
          accessTokenExpiration: tokenDecoded.exp,
        })

        const authState = loadState<AuthState>(authKeys.auth)

        let hasSameVisibleStores = false
        const tokenVisibleStores = tokenDecoded.metadata.visibleStores

        if (authState?.visibleStores !== undefined && tokenVisibleStores !== undefined) {
          if (authState.visibleStores.length === tokenVisibleStores.length && tokenVisibleStores.length !== 0) {
            hasSameVisibleStores = !!tokenVisibleStores.reduce(
              (prev, current) => !!(prev && !!authState.visibleStores.find((storeId) => storeId === current)),
              true,
            )
          } else {
            hasSameVisibleStores = true
          }
        } else if (authState?.visibleStores === tokenVisibleStores) hasSameVisibleStores = true

        let hasSameDutyFreeStores = true
        const tokenDutyFreeStores = tokenDecoded.metadata.dutyFreeStores

        if (authState?.dutyFreeStores !== undefined && tokenDutyFreeStores !== undefined) {
          if (authState.dutyFreeStores.length === tokenDutyFreeStores.length && tokenDutyFreeStores.length !== 0) {
            hasSameDutyFreeStores = !!tokenDutyFreeStores.reduce(
              (prev, current) => !!(prev && !!authState.dutyFreeStores?.find((storeId) => storeId === current)),
              true,
            )
          } else {
            hasSameDutyFreeStores = true
          }
        } else if (authState?.dutyFreeStores === tokenDutyFreeStores) hasSameDutyFreeStores = true

        if (
          `${authState?.defaultStoreId}` !== `${tokenDecoded.metadata.defaultStore}` ||
          !hasSameVisibleStores ||
          !hasSameDutyFreeStores
        ) {
          return signOutRedirect()
        }

        const { config } = error.response

        const newConfig = { ...config, data: config?.data ? JSON.parse(config?.data) : undefined }

        return retryRequest(newConfig, newAccessToken)
      } catch (e) {
        console.error('Error renewing credentials or retrying request')
        signOutRedirect()

        throw error
      }
    }

    throw error
  }
}

export function insertAuthorizationHeader(api) {
  try {
    const accessToken = getAccessToken()
    return { ...api, headers: { Authorization: `Bearer ${accessToken}` } }
  } catch (err) {
    return Promise.reject(err)
  }
}
