import React from 'react'

export interface TabItem {
  label: string
  value: string
}

interface Props {
  items: TabItem[]
  value: string
  onChange: (value: string) => void
  className?: string
}

const TabSelector = ({ items, value, onChange, className }: Props) => {
  return (
    <div className={`${className || 'inline-block flex border-b-2 space-x-3'}`}>
      {items.map((item) => (
        <div className="cursor-pointer -mb-1" key={item.value} onClick={() => onChange(item.value)}>
          <span className={`block select-none ${value === item.value ? 'font-bold mb-1' : ''}`}>{item.label}</span>
          <span className={`w-full block ${value === item.value ? 'h-2 rounded-lg bg-dark' : ''}`}></span>
        </div>
      ))}
    </div>
  )
}

export default TabSelector
