import { FavoriteProduct } from '../../types'
import { AppState } from './types'

type SET_STORES = {
  type: 'SET_STORES'
  stores: AppState['stores']
}

type SET_CURRENT_STORE = { type: 'SET_CURRENT_STORE'; currentStore: AppState['currentStore'] }
type UPDATE_CURRENT_STORE_DATA = {
  type: 'UPDATE_CURRENT_STORE_DATA'
  newCurrentStoreData: Partial<AppState['currentStore']>
}

type ADD_STORE_FAVORITE_PRODUCT = {
  type: 'ADD_STORE_FAVORITE_PRODUCT'
  currentStoreId: string
  newFavoriteProduct: FavoriteProduct
}

type ADD_STORE_MULTIPLE_FAVORITE_PRODUCT = {
  type: 'ADD_STORE_MULTIPLE_FAVORITE_PRODUCT'
  currentStoreId: string
  newFavoriteProducts: FavoriteProduct[]
}

type DELETE_STORE_FAVORITE_PRODUCT = {
  type: 'DELETE_STORE_FAVORITE_PRODUCT'
  currentStoreId: string
  deletedFavoriteProduct: FavoriteProduct
}

type FETCH_STORES_BEGINNING = { type: 'FETCH_STORES_BEGINNING' }
type FETCH_STORES_FINISHED = { type: 'FETCH_STORES_FINISHED' }

type CLEAR_STORES_DATA = { type: 'CLEAR_STORES_DATA' }

type FETCH_DEFAULT_STORE_BEGINNING = { type: 'FETCH_DEFAULT_STORE_BEGINNING' }
type FETCH_DEFAULT_STORE_FINISHED = { type: 'FETCH_DEFAULT_STORE_FINISHED' }

type SET_DEFAULT_IMAGE = { type: 'SET_DEFAULT_IMAGE'; defaultProductImage: string }

type SET_ONBOARDING_RUN = { type: 'SET_ONBOARDING_RUN'; onboardingRun: boolean }
type SET_ONBOARDING_STEP = { type: 'SET_ONBOARDING_STEP'; onboardingStep: number }

export type AppActions =
  | FETCH_STORES_BEGINNING
  | FETCH_STORES_FINISHED
  | SET_STORES
  | FETCH_DEFAULT_STORE_BEGINNING
  | FETCH_DEFAULT_STORE_FINISHED
  | SET_CURRENT_STORE
  | SET_DEFAULT_IMAGE
  | UPDATE_CURRENT_STORE_DATA
  | ADD_STORE_FAVORITE_PRODUCT
  | ADD_STORE_MULTIPLE_FAVORITE_PRODUCT
  | DELETE_STORE_FAVORITE_PRODUCT
  | CLEAR_STORES_DATA
  | SET_ONBOARDING_RUN
  | SET_ONBOARDING_STEP

function reducer(state: AppState, action: AppActions): AppState {
  switch (action.type) {
    case 'FETCH_STORES_BEGINNING': {
      return { ...state, isLoadingStores: true }
    }
    case 'FETCH_STORES_FINISHED': {
      return { ...state, isLoadingStores: false }
    }
    case 'FETCH_DEFAULT_STORE_BEGINNING': {
      return { ...state, isLoadingCurrentStore: true }
    }
    case 'FETCH_DEFAULT_STORE_FINISHED': {
      return { ...state, isLoadingCurrentStore: false }
    }
    case 'SET_CURRENT_STORE': {
      const currentStore = state.stores.find((store) => store.id === action.currentStore?.id)
      const newStores = state.stores.filter((store) => store.id !== action.currentStore?.id)
      return {
        ...state,
        stores: currentStore ? [currentStore, ...newStores] : state.stores,
        currentStore: action.currentStore,
        selectedStoreId: action.currentStore?.id ?? '',
      }
    }

    case 'UPDATE_CURRENT_STORE_DATA': {
      if (!state.currentStore) return state

      const storeIndex = state.stores.findIndex((store) => `${store.id}` === `${state.currentStore?.id}`)

      const newStores = [...state.stores]

      if (storeIndex !== -1) {
        newStores[storeIndex].label = action.newCurrentStoreData?.label ?? newStores[storeIndex].label
        newStores[storeIndex].storeName = action.newCurrentStoreData?.storeName ?? newStores[storeIndex].storeName
      }

      return {
        ...state,
        stores: newStores.sort((a, b) => {
          return a.storeName.localeCompare(b.storeName, 'es', { ignorePunctuation: true })
        }),
        currentStore: { ...state.currentStore, ...action.newCurrentStoreData },
      }
    }

    case 'SET_STORES': {
      const currentStore = action.stores.find((store) => store.id === state.currentStore?.id)
      const newStores = action.stores.filter((store) => store.id !== state.currentStore?.id)

      return { ...state, stores: currentStore ? [currentStore, ...newStores] : action.stores }
    }

    case 'ADD_STORE_FAVORITE_PRODUCT': {
      if (!state.currentStore) return state

      const newFavorites = [action.newFavoriteProduct, ...(state.currentStore?.favoritesProducts ?? [])]

      return {
        ...state,
        currentStore: {
          ...state.currentStore,
          favoritesProducts: newFavorites,
        },
      }
    }

    case 'ADD_STORE_MULTIPLE_FAVORITE_PRODUCT': {
      if (!state.currentStore) return state

      const newFavorites = [...action.newFavoriteProducts, ...(state.currentStore?.favoritesProducts ?? [])]

      return {
        ...state,
        currentStore: {
          ...state.currentStore,
          favoritesProducts: newFavorites,
        },
      }
    }

    case 'DELETE_STORE_FAVORITE_PRODUCT': {
      if (!state.currentStore) return state

      const newFavorites = state.currentStore?.favoritesProducts
        ? [
            ...state.currentStore?.favoritesProducts.filter(
              ({ productId }) => productId !== action.deletedFavoriteProduct.productId,
            ),
          ]
        : []

      return {
        ...state,
        currentStore: {
          ...state.currentStore,
          favoritesProducts: newFavorites,
        },
      }
    }

    case 'CLEAR_STORES_DATA': {
      return { ...state, stores: [], currentStore: undefined, selectedStoreId: '' }
    }

    case 'SET_DEFAULT_IMAGE': {
      return { ...state, defaultProductImage: action.defaultProductImage }
    }

    case 'SET_ONBOARDING_RUN': {
      return { ...state, onboardingRun: action.onboardingRun }
    }

    case 'SET_ONBOARDING_STEP': {
      return { ...state, onboardingStep: action.onboardingStep }
    }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
