import { CartContextInitialState, CartContextState } from './context'
import { Cart, CartProduct, CartSummary } from './types'

type SET_CART = { type: 'SET_CART'; cart: Cart }
type SET_CART_SUMMARY = { type: 'SET_CART_SUMMARY'; cartSummary: CartSummary }
type REMOVE_PRODUCT = { type: 'REMOVE_PRODUCT'; sku: string }
type REPLACE_PRODUCT = { type: 'REPLACE_PRODUCT'; product: CartProduct }
type REMOVE_BUNDLE = { type: 'REMOVE_BUNDLE'; sku: string }
type REPLACE_BUNDLE = { type: 'REPLACE_BUNDLE'; bundle: { sku: number; bundleQuantity: number } }
type EMPTY_CART = { type: 'EMPTY_CART' }

export type CartActions =
  | SET_CART
  | EMPTY_CART
  | SET_CART_SUMMARY
  | REMOVE_PRODUCT
  | REPLACE_PRODUCT
  | REMOVE_BUNDLE
  | REPLACE_BUNDLE

function reducer(state: CartContextState, action: CartActions): CartContextState {
  switch (action.type) {
    case 'SET_CART': {
      return { ...state, cart: action.cart }
    }
    case 'SET_CART_SUMMARY': {
      return { ...state, summary: action.cartSummary }
    }
    case 'REMOVE_PRODUCT': {
      const newCart = { ...state.cart }
      const items = newCart.items.filter(({ sku }) => sku !== +action.sku)

      return { ...state, cart: { ...newCart, items } }
    }
    case 'REMOVE_BUNDLE': {
      const newCart = { ...state.cart }
      const bundles = newCart.bundles.filter(({ sku }) => sku !== +action.sku)

      return { ...state, cart: { ...newCart, bundles } }
    }
    case 'REPLACE_PRODUCT': {
      const newCart = { ...state.cart }
      const productIndex = newCart.items.findIndex(({ sku }) => sku === action.product.sku)
      newCart.items[productIndex] = { ...newCart.items[productIndex], ...action.product }
      return { ...state, cart: newCart }
    }
    case 'REPLACE_BUNDLE': {
      const newCart = { ...state.cart }
      const productIndex = newCart.bundles.findIndex(({ sku }) => sku === action.bundle.sku)
      newCart.bundles[productIndex] = { ...newCart.bundles[productIndex], ...action.bundle }
      return { ...state, cart: newCart }
    }
    case 'EMPTY_CART':
      return CartContextInitialState
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
