import axios, { AxiosInstance } from 'axios'

import * as config from '../../config'
import { handleServiceError } from '../../utils/errors'

const client: AxiosInstance = axios.create({
  baseURL: `https://${config.ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/${config.ALGOLIA_INDEX_NAME}`,
  headers: {
    'X-Algolia-API-Key': config.ALGOLIA_API_KEY,
    'X-Algolia-Application-Id': config.ALGOLIA_APP_ID,
  },
})

client.interceptors.response.use(
  (res) => res,
  async (error) => {
    return Promise.resolve(handleServiceError(error))
  },
)

export default client
