import { navigate } from 'gatsby'
import React, { useEffect, useReducer } from 'react'

import { updateUser } from '../../services/user'
import { loadState, saveState } from '../../utils/localstorage'
import { authKeys } from '../../utils/store'
import { useAuth } from '..'
import {
  addFavoriteProduct,
  addMultipleFavoriteProduct,
  changeStore,
  clearStoresData,
  deleteFavoriteProduct,
  fetchDefaultStore,
  fetchStores,
  getProductUnitsLimits,
  setOnboardingRun,
  setOnboardingStep,
  updateCurrentStoreData,
} from './actions'
import { AppContext, AppDefaultState, DispatchContext } from './context'
import reducer from './reducer'
import { AppProviderProps, AppState } from './types'

export function AppProvider(props: AppProviderProps) {
  const {
    children,
    defaultProductImage,
    companyLogo,
    companyEmailAddress,
    companyLogoColorful,
    companyLogoColorfulAlt,
    mobileAppLogo,
    companyPhoneNumber,
    welcomeVideo,
    productsUnitsLimits,
    loginBackgroundImageUrl,
    loginLogo,
  } = props

  const persistedState = loadState<AppState>(authKeys.app)

  let initialState: AppState = AppDefaultState

  if (persistedState) {
    // Check if has been past an hour since last stores update
    const shouldUpdateStores = (persistedState.lastStoresUpdate || 0) + 1000 * 60 * 60 < Date.now()

    if (shouldUpdateStores) {
      initialState = { ...persistedState, stores: [], currentStore: undefined, lastStoresUpdate: Date.now() }
    } else {
      initialState = persistedState
    }
  }

  const initialStateWithContentfulProps = {
    ...initialState,
    defaultProductImage,
    companyLogo,
    companyEmailAddress,
    companyLogoColorful,
    companyLogoColorfulAlt,
    mobileAppLogo,
    companyPhoneNumber,
    welcomeVideo,
    productsUnitsLimits,
    loginBackgroundImageUrl,
    loginLogo,
  }
  const [state, dispatch] = useReducer(reducer, initialStateWithContentfulProps)

  const isCurrentStoreLoaded = state.currentStore?.id

  const {
    state: { defaultStoreId, needPasswordUpdate, visibleStores, id: userId, dutyFreeStores },
    changeAuthState,
  } = useAuth()

  useEffect(() => {
    if (defaultStoreId && !isCurrentStoreLoaded && !needPasswordUpdate) {
      fetchDefaultStore(dispatch)(state.selectedStoreId || defaultStoreId, dutyFreeStores).catch((err) => {
        throw err
      })
    }

    if (isCurrentStoreLoaded && state.stores.length === 0) {
      fetchStores(dispatch)(visibleStores, defaultStoreId)
    }
  }, [defaultStoreId, state.currentStore, needPasswordUpdate])

  useEffect(() => {
    saveState(authKeys.app, state)
  }, [state])

  useEffect(() => {
    if (
      !state.isLoadingStores &&
      !state.isLoadingCurrentStore &&
      state.stores.length > 0 &&
      state?.currentStore?.deletedAt
    ) {
      const nonDeletedStores = state.stores.filter((store) => !store.deletedAt)
      if (nonDeletedStores.length > 0) {
        const id = nonDeletedStores[0].id
        changeStore(dispatch)(id)
        updateUser({
          userId,
          defaultStoreId: parseInt(id),
        })
        changeAuthState({
          defaultStoreId: id,
        })
      } else {
        navigate('/error-deleted')
      }
    }
  }, [state.isLoadingStores, state.isLoadingCurrentStore, state?.currentStore?.deletedAt, state.stores])

  return (
    <AppContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </AppContext.Provider>
  )
}

export function useAppStore() {
  const state = React.useContext(AppContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }

  return {
    state,
    changeStore: changeStore(dispatch),
    updateCurrentStoreData: updateCurrentStoreData(dispatch),
    addStoreFavoriteProduct: addFavoriteProduct(dispatch),
    addStoreMultipleFavoriteProduct: addMultipleFavoriteProduct(dispatch),
    deleteStoreFavoriteProduct: deleteFavoriteProduct(dispatch),
    clearStoresData: clearStoresData(dispatch),
    getProductUnitsLimits: getProductUnitsLimits(state),
    setOnboardingRun: setOnboardingRun(dispatch),
    setOnboardingStep: setOnboardingStep(dispatch),
  }
}
