import React from 'react'

import Checkbox from '../../atoms/Checkbox'

export interface TableRowProps {
  isActive?: boolean
  selectable?: boolean
  content: React.ReactNode[]
  value?: string
  valign?: 'baseline' | 'bottom' | 'middle' | 'top'
  className?: string
  selectedClassName?: string
  onSelect?: (value?: string) => void
  onUnselect?: (value?: string) => void
}

const TableRow = ({
  isActive,
  value,
  content,
  selectable,
  onSelect,
  onUnselect,
  className,
  selectedClassName = '',
  valign = 'top',
}: TableRowProps) => {
  return (
    <tr>
      {selectable && (
        <td
          valign={valign}
          className={`${className || 'border-t  first:pl-3 desktop:first:pl-10 last:pr-3 desktop:last:pr-10 py-3'} ${
            isActive ? selectedClassName : ''
          }`}
          style={{ width: '14px', height: '14px', paddingRight: '8px', boxSizing: 'content-box' }}
        >
          <div style={{ marginTop: '-0.5px' }}>
            <Checkbox
              value={value ?? ''}
              checked={isActive}
              onChange={() => (isActive ? onUnselect?.(value) : onSelect?.(value))}
            />
          </div>
        </td>
      )}
      {content.map((field, i) => (
        <td
          valign={valign}
          key={i}
          className={`${
            className ||
            'border-t text-xs desktop:text-sm  first:pl-3 desktop:first:pl-10 px-1 desktop:px-4 last:pr-3 desktop:last:pr-10 py-3 mb-auto'
          } ${isActive ? selectedClassName : ''}`}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default TableRow
