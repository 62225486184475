import React from 'react'

export interface TabProps {
  label?: string
  icon?: React.ReactNode
  isActive?: boolean
  onClick: () => void
}

const Tab = (props: TabProps) => {
  const { label, icon, isActive = false, onClick } = props
  const classActive = `flex justify-center items-center border-0 w-auto px-2 cursor-pointer bg-white text-primary text-sm font-bold`
  const classInactive = `flex justify-center items-center border-0 w-auto px-2 cursor-pointer bg-primary hover:bg-tertiary text-white text-sm font-bold`
  return (
    <div className={isActive ? classActive : classInactive} onClick={onClick} style={{ minWidth: 130 }}>
      {icon}
      <span className="ml-2 text-xs">{label}</span>
    </div>
  )
}

export default Tab
