import './src/config/site.css'

import * as React from 'react'

import { COUNTRY } from './src/config'
import unleashClient from './src/services/clients/unleash'
import Shell from './src/Shell'

export const wrapRootElement = ({ element }) => {
  return <Shell>{element}</Shell>
}

/* Load network error page */
const count = 0
export const onPrefetchPathname = ({ loadPage }) => {
  if (count === 0) {
    loadPage('/network-error')
  }
}

const checkIsSiteMaintenance = () =>
  unleashClient.on('update', () => {
    const IS_SITE_MAINTENANCE = unleashClient.isEnabled(`ecommerce-b2b-${COUNTRY}.site.maintenance`)
    if (IS_SITE_MAINTENANCE && window.location.pathname !== '/maintenance') {
      window.location.pathname = '/maintenance'
    }
  })

export const onClientEntry = () => {
  checkIsSiteMaintenance()
}

export const onPreRouteUpdate = () => {
  checkIsSiteMaintenance()
}
