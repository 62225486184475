import React, { HTMLAttributes } from 'react'

import Icon from '../../atoms/Icon'

const cardClasses = 'bg-white flex flex-col rounded p-6'
const cardStyles = { width: '340px', maxWidth: '100vw' }
const headerClasses = 'flex'
const bodyClasses = 'p-2'
const footerClasses = 'p-2'

export interface AlertCardProps extends HTMLAttributes<HTMLDivElement> {
  onClose?: () => void
  noCloseButton?: boolean
  headerContent?: React.ReactNode
  footerContent?: React.ReactNode
  footerClassName?: string
  headerClassName?: string
  bodyClassName?: string
  useDefaultStyles?: boolean
  useDefaultBodyClasses?: boolean
  useDefaultFooterClasses?: boolean
  closeIconSize?: number
}

const AlertCard = ({
  className,
  children,
  headerContent,
  footerContent,
  onClose,
  footerClassName,
  bodyClassName,
  headerClassName,
  useDefaultStyles = true,
  useDefaultBodyClasses = true,
  useDefaultFooterClasses = true,
  closeIconSize = 20,
}: AlertCardProps) => (
  <div className={`${cardClasses} ${className ?? ''}`} style={useDefaultStyles ? cardStyles : undefined}>
    <div className={`${headerClasses} ${headerClassName ?? ''}`}>
      <div className="flex-grow align-top">{headerContent ?? null}</div>
      <a onClick={() => (onClose ? onClose() : null)} className="cursor-pointer">
        <Icon id="close" size={closeIconSize} />
      </a>
    </div>
    <div className={`${useDefaultBodyClasses ? bodyClasses : ''} ${bodyClassName ?? ''}`}>{children ?? null}</div>
    <div className={`${useDefaultFooterClasses ? footerClasses : ''} ${footerClassName ?? ''}`}>
      {footerContent ?? null}
    </div>
  </div>
)

export default AlertCard
