import { navigate } from 'gatsby'
import React from 'react'

import Icon from '../atoms/Icon'

interface BreadCrumbProps {
  items: { title: string; path?: string; onClick?: () => void }[]
  separator?: React.ReactNode
  containerClassName?: string
}

// TODO: add logic to this component that will automatically construct the path and title
// for each item if items is not provided

const BreadCrumb = ({ items, containerClassName, separator }: BreadCrumbProps) => {
  const renderItems = () =>
    items.reduce<React.ReactNode[]>((nodes, { title, path, onClick }, index) => {
      const node = (
        <span
          key={`breacrumb-item-${index}`}
          className={`cursor-pointer ${index === items.length - 1 ? 'font-bold text-primary' : 'text-black'}`}
          onClick={() => (onClick ? onClick() : path && navigate(path))}
        >
          {title}
        </span>
      )

      nodes.push(node)
      if (index < items.length - 1)
        nodes.push(
          separator ?? (
            <Icon
              id="chevron_right"
              className={`${index === items.length - 2 ? 'text-primary' : 'text-black'} inline-block mx-1.5 -mb-0.5`}
              size={24}
            />
          ),
        )
      return nodes
    }, [])

  return <p className={`$text-black flex items-center px-2 text-md ${containerClassName}`}>{renderItems()}</p>
}

export default BreadCrumb
