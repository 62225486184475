import { createContext } from 'react'

import { CartActions } from './reducer'
import { Cart, CartSummary } from './types'

export interface CartContextState {
  cart: Cart
  summary: CartSummary
}

export enum ProductFromScreenValues {
  B2B_HOME_SUGGESTED_PRODUCT = 100,
  B2B_CATALOG_SUGGESTED_PRODUCT = 101,
  B2B_CART_SUGGESTED_PRODUCT = 102,
  B2B_HOME_HISTORIC_SUGGESTED_PRODUCT = 103,
  B2B_CATALOG_HISTORIC_SUGGESTED_PRODUCT = 104,
  B2B_CART_HISTORIC_SUGGESTED_PRODUCT = 105,
}

export const CartContextInitialState: CartContextState = {
  cart: { isModified: false, isRepeatedFrom: null, items: [], bundles: [], storeId: -1, userId: '' },
  summary: {
    createdAt: null,
    notVisibleDiscountTotal: 0,
    grossTotal: 0,
    actualReductionPercentage: 0,
    visibleDiscountTotalReductionPercentage: 0,
    visibleDiscountTotalReduction: 0,
    products: [],
    bundles: [],
    total: 0,
  },
}

export const CartContext = createContext<CartContextState>(CartContextInitialState)

export type CartDispatch = (action: CartActions) => void
export const DispatchContext = createContext<CartDispatch | undefined>(undefined)
