import { Product } from '../types'

export interface GtagEvent {
  eventCategory: EventCategory
  eventAction: string
  eventLabel: string
}

export interface GtagEventGA4 {
  event: string
  data: any
}

export const sendGtagEvent = (gtagEvent: GtagEvent) => {
  if (window && window.gtag) {
    window.gtag('event', gtagEvent.eventAction, {
      event_category: gtagEvent.eventCategory,
      event_label: gtagEvent.eventLabel,
    })
  }
}

export const sendGtagEventGA4 = (gtagEvent: GtagEventGA4) => {
  if (window && window.gtag) {
    window.gtag('event', gtagEvent.event, { ...gtagEvent.data })
  }
}

export const parseItemData = (item: Product, index = 0, quantity?: number) => {
  const parseItems = {
    item_id: item.sku,
    item_name: item.name,
    index,
    item_brand: item.brand,
    item_category: item.category,
    item_variant: item.variation,
    discount: item.rawPrice - item.price,
    coupon: item.discount,
    price: item.price,
  }

  if (quantity !== undefined) {
    return { ...parseItems, quantity }
  }

  return parseItems
}

export const parseItemsData = (items: Product[]) => {
  return items.map((item, index) => parseItemData(item, index))
}

export enum EventCategory {
  Home = 'Home',
  Products = 'Products',
  Promotions = 'Promotions',
  Cart = 'Cart',
  Orders = 'Orders',
  OrderDetails = 'OrderDetails',
  Layout = 'Layout',
  Checkout = 'Checkout',
  StaticPage = 'StaticPage',
  SignIn = 'SignIn',
  AccountActivation = 'Account Activation',
  Invoices = 'Invoices',
  Favorites = 'Favorites',
  RecommendedFavorites = 'Recommended favorites',
  ProductDetails = 'Product Details',
  Support = 'Support',
  SignInSupport = 'SignIn Support',
  InvoiceDetails = 'Invoices Details',
}
