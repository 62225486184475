import { currentCountry } from '../i18n'

export const FACETS = {
  CATEGORY: 'mainCategoryName',
  BRAND: 'pimBrand',
  DUTY_FREE: 'cifValues.branchOfficeId',
}

export const CATEGORIES = {
  BEVERAGES: 'Bebidas',
  FROZEN: 'Congelados',
  OTHER: 'Otros',
}

export const SUBCATEGORIES = {
  ICE_CREAM: 'Helados',
}

export const ORDER_TYPES = { STANDART: 'standard', DUTY_FREE: 'duty_free', FROZEN: 'frozen' }

export const MAX_UNITS_BY_CATEGORY = {
  [`${CATEGORIES.OTHER}-cl`]: 30,
}

export const getMaxUnitsByCategory = (categoryName: string) =>
  MAX_UNITS_BY_CATEGORY[`${categoryName}-${currentCountry.toLowerCase()}`]

export const noResultsTexts = {
  NO_SEARCH: 'Ups! No encontramos resultados para tu búsqueda',
  NO_MORE_FAVORITES: 'No tienes más productos favoritos',
  NO_FAVORITES: 'Aún no tienes productos favoritos',
}
