import axios, { AxiosInstance } from 'axios'

import * as config from '../../config'
import { sendGtagEventGA4 } from '../../utils/analytics'
import { handleServiceError } from '../../utils/errors'
import { ErrorSource, reportError, Severity } from '../../utils/sentry'
import { authStoreClear } from '../../utils/store'
import { fetch } from '../clients/authentication'

const headers = { Accept: 'application/json', 'Content-Type': 'application/json' }

export interface SignInParams {
  username: string
  password: string
  refresh?: boolean
}
export interface SignInByKeyParams {
  key: string
  requestId?: string
  origin?: string
}

export const createSignInInstance = (client: AxiosInstance) => async (params: SignInParams) => {
  try {
    const data = { username: params.username, password: params.password, refreshToken: params.refresh }
    const { data: response } = await client('/auth/login', { data, headers, method: 'POST' })
    return response
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at authentication login. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }

      reportError({
        message: errorInfo.message,
        level: Severity.Critical,
        metadata: { error: errorInfo, response: errorInfo.response },
        page: '/signin',
        source: ErrorSource.AUTH_SERVICE,
      })

      console.error('Cannot complete sign in process.')
    }

    return Promise.resolve(handleServiceError(error))
  }
}
export const createSignInByKeyInstance =
  (client: AxiosInstance) =>
  async ({ key }: SignInByKeyParams) => {
    try {
      const { data: response } = await client(`/auth/login/${key}`, { headers, method: 'GET' })
      return response
    } catch (error: any) {
      if (error.response?.status >= 400) {
        const message = `${
          error.response?.status === 400
            ? 'The key is expired'
            : error.response?.status === 404
              ? 'Another key was created'
              : 'Error ocurred at authentication login with key'
        }.`

        sendGtagEventGA4({
          event: `login_error`,
          data: {
            message,
          },
        })
      }
      if (error.response?.status >= 500) {
        const errorInfo = {
          message: `Error ocurred at authentication login with key. message="${error?.message}"; response="${error?.response}"`,
          response: error?.response,
        }

        reportError({
          message: errorInfo.message,
          level: Severity.Critical,
          metadata: { error: errorInfo, response: errorInfo.response },
          page: '/signin',
          source: ErrorSource.AUTH_SERVICE,
        })

        console.error('Cannot complete sign in process.')
      }

      return Promise.resolve(handleServiceError(error))
    }
  }

export const signInInsider = () => createSignInInstance(fetch)
export const signIn = createSignInInstance(axios.create({ baseURL: config.EMBONOR_BACKEND_FOR_FRONTEND }))
export const signInByKey = createSignInByKeyInstance(axios.create({ baseURL: config.EMBONOR_BACKEND_FOR_FRONTEND }))

export const signOutRedirect = () => {
  authStoreClear()
  window.location.href = `${window.location.origin}/signin`
}
