import {
  addCartBundle,
  addCartProduct,
  createRepeatedCart,
  deleteBundle,
  deleteProduct,
  emptyCart,
  getCustomerCart,
  getCustomerCartSummary,
  replaceBundle,
  replaceProduct,
} from '../../services/cart'
import { CartDispatch } from './context'
import { CartBundle, CartProduct, CartProducts } from './types'

export const actionGetCartSummary = (dispatch: CartDispatch, storeId: string) => {
  return async () => {
    const cartSummary = await getCustomerCartSummary(storeId)
    dispatch({ type: 'SET_CART_SUMMARY', cartSummary })
  }
}

export const actionGetCustomerCart = (dispatch: CartDispatch, storeId: string) => {
  return async () => {
    const cart = await getCustomerCart(storeId)
    dispatch({ type: 'SET_CART', cart })
  }
}

export const actionEmptyCustomerCart = (dispatch: CartDispatch, storeId: string) => {
  return async () => {
    await emptyCart(storeId)
    const cart = await getCustomerCart(storeId)
    dispatch({ type: 'SET_CART', cart })
  }
}

export const actionAddCartProduct = (dispatch: CartDispatch, storeId: string) => {
  return async (product: CartProduct) => {
    const cart = await addCartProduct({ storeId, product })
    dispatch({ type: 'SET_CART', cart })
  }
}

export const actionRemoveCartProduct = (dispatch: CartDispatch, storeId: string) => {
  return async (sku: string) => {
    dispatch({ type: 'REMOVE_PRODUCT', sku })
    await deleteProduct({ storeId, sku })
  }
}

export const actionReplaceProduct = (dispatch: CartDispatch, storeId: string) => {
  return async (product: CartProduct) => {
    dispatch({ type: 'REPLACE_PRODUCT', product })
    await replaceProduct({ storeId, product })
  }
}

export const actionAddCartBundle = (dispatch: CartDispatch, storeId: string) => {
  return async (bundle: CartBundle) => {
    const cart = await addCartBundle({ storeId, bundle })
    dispatch({ type: 'SET_CART', cart })
  }
}

export const actionRemoveCartBundle = (dispatch: CartDispatch, storeId: string) => {
  return async (sku: string) => {
    dispatch({ type: 'REMOVE_BUNDLE', sku })
    await deleteBundle({ storeId, sku })
  }
}

export const actionReplaceBundle = (dispatch: CartDispatch, storeId: string) => {
  return async (bundle: Omit<CartBundle, 'products'>) => {
    dispatch({ type: 'REPLACE_BUNDLE', bundle: { sku: bundle.sku, bundleQuantity: bundle.bundleQuantity } })
    await replaceBundle({ storeId, sku: bundle.sku, bundleQuantity: bundle.bundleQuantity })
  }
}

export const actionCreateClonedCartFromOrder = (dispatch: CartDispatch, storeId: string) => {
  return async ({ products, isRepeatedFrom }: { products: CartProducts; isRepeatedFrom: number }) => {
    const cart = await createRepeatedCart({ storeId, products, isRepeatedFrom })
    dispatch({ type: 'SET_CART', cart })
  }
}
