import { motion } from 'framer-motion'
import React, { ReactNode } from 'react'

interface ButtonProps {
  onClick?: () => void
  label: string
  type: 'primary' | 'secondary' | 'tertiary'
  icon?: ReactNode
}

const classes = {
  primary: 'bg-transparent border-none text-primary hover:text-tertiary font-semibold',
  secondary: 'bg-transparent border-none text-tertiary hover:text-primary font-semibold',
  tertiary: 'bg-transparent border-none text-black hover:text-dark font-semibold',
}

const Link = (props: ButtonProps) => {
  const { type, onClick, label, icon, ...restProps } = props

  return (
    <motion.button
      type="button"
      whileTap={{ scale: 0.95 }}
      {...restProps}
      className={`text-md ${classes[type]} mb-5 mt-4 py-2 rounded`}
      style={{ outline: 'none' }}
      onClick={onClick}
    >
      {icon}
      {label}
    </motion.button>
  )
}

export default Link
