import { UnleashClient } from 'unleash-proxy-client'

import { STAGE, UNLEASH_APP_NAME, UNLEASH_CLIENT_KEY, UNLEASH_ENV, UNLEASH_URL } from '../../config'

const unleashClient: UnleashClient = new UnleashClient({
  url: UNLEASH_URL,
  clientKey: UNLEASH_CLIENT_KEY,
  appName: UNLEASH_APP_NAME,
  refreshInterval: STAGE === 'prd' ? 60 : 15,
  environment: UNLEASH_ENV,
})

unleashClient.start()

export default unleashClient
