import React from 'react'

interface Props {
  size?: number
  className?: string
}
const LoadingIndicator = ({ size = 24, className = '' }: Props) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 24 24`}
      version="1.1"
      className={`animate-spin ${className}`}
    >
      <g id="Icons/loading-dark" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id={`Box-${size}pt`} x="0" y="0" width={size} height={size}></rect>
        <g id="minus-circle"></g>
        <path
          d="M12,0.75 C18.2132034,0.75 23.25,5.78679656 23.25,12 C23.25,18.2132034 18.2132034,23.25 12,23.25 C5.78679656,23.25 0.75,18.2132034 0.75,12 C0.75,5.78679656 5.78679656,0.75 12,0.75 Z M12,3.3452381 C7.22010699,3.3452381 3.3452381,7.22010699 3.3452381,12 C3.3452381,16.779893 7.22010699,20.6547619 12,20.6547619 C16.779893,20.6547619 20.6547619,16.779893 20.6547619,12 C20.6547619,7.22010699 16.779893,3.3452381 12,3.3452381 Z"
          id="Oval"
          fill="#E8EBF7"
        ></path>
        <g id="circle" transform="translate(3.000000, 0.000000)" fill="#F40009" fillRule="nonzero">
          <path
            d="M9,0.5 C15.3512746,0.5 20.5,5.64872538 20.5,12 C20.5,12.8284271 19.8284271,13.5 19,13.5 C18.1715729,13.5 17.5,12.8284271 17.5,12 C17.5,7.30557963 13.6944204,3.5 9,3.5 C6.72504915,3.5 4.59556422,4.39576655 3.01278732,5.96648592 C2.4247654,6.55002831 1.47502487,6.54639694 0.891482478,5.95837502 C0.307940086,5.3703531 0.31157146,4.42061258 0.899593379,3.83707018 C3.03875118,1.7142084 5.92526051,0.5 9,0.5 Z"
            id="Oval"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default LoadingIndicator
