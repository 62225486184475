import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactNode, useEffect, useState } from 'react'

import {
  AppProvider,
  AuthProvider,
  LoadingProvider,
  ToastContextProvider as ToastProvider,
  useAppStore,
  useAuth,
} from './contexts'
import SiteHead from './core/SiteHead'
import { signOutRedirect } from './services/auth'
import { initSentry } from './utils/sentry'
import { getAccessToken, getRefreshTokenExpiration } from './utils/store'

const Shell = (props: { children: ReactNode }) => {
  const [showContent, setShowContent] = useState(false)
  const {
    state: { isAuth, needPasswordUpdate, email: userEmail, defaultStoreId, visibleStores, dutyFreeStores, id: userId },
  } = useAuth()
  const {
    state: { currentStore },
  } = useAppStore()

  useEffect(() => {
    initSentry()
    const isLogged = isAuth && !(needPasswordUpdate && getAccessToken())
    const refreshTokenExpiration = getRefreshTokenExpiration()
    const isRefreshTokenExpired = !refreshTokenExpiration || Date.now() > refreshTokenExpiration

    if (refreshTokenExpiration && isRefreshTokenExpired && window.location.pathname.match(/^\/app(\/)?/)) {
      signOutRedirect()
    } else if (window.location.pathname.match(/^\/app(\/)?/) && !isLogged) {
      window.location.pathname = '/signin'
    } else if (window.location.pathname.match(/^\/sign(in|up)(\/)?$/) && isLogged) {
      window.location.pathname = '/app'
    } else {
      setShowContent(true)
    }
  }, [])

  useEffect(() => {
    const isLogged = isAuth && !(needPasswordUpdate && getAccessToken())
    if (isLogged) {
      window.hj?.('identify', userId, {
        email: userEmail,
        defaultStoreId: `${defaultStoreId}`,
        visibleStores: visibleStores?.map((store) => `${store}`).join(','),
        dutyFreeStores: dutyFreeStores?.map((store) => `${store}`).join(','),
        currentStoreId: currentStore?.id ?? '',
      })
    } else {
      window.hj?.('identify', null, {})
    }
  }, [userEmail, defaultStoreId, visibleStores, dutyFreeStores, isAuth, needPasswordUpdate, currentStore?.id])

  if (showContent) {
    return <>{props.children}</>
  }

  return null
}

export default (props: { children: ReactNode }) => {
  const { contentfulSettings, allContentfulProductUnitsLimitation } = useStaticQuery(graphql`
    query ShellQuery {
      contentfulSettings {
        defaultProductImage {
          file {
            url
          }
        }
        companyLogo {
          file {
            url
          }
        }
        companyLogoColorful {
          file {
            url
          }
        }
        companyLogoColorfulAlt {
          file {
            url
          }
        }
        mobileAppLogo {
          file {
            url
          }
        }
        welcomeVideo {
          title
          description
          thumbnail {
            file {
              url
            }
          }
          video {
            file {
              url
            }
          }
        }
        companyEmailAddress
        companyPhoneNumber
        loginSettings {
          backgroundImage {
            file {
              url
            }
          }
          logo {
            file {
              url
            }
          }
        }
      }
      allContentfulProductUnitsLimitation {
        edges {
          node {
            sku
            minimumUnits
            maximumUnits
            unitsMultiple
          }
        }
      }
    }
  `)

  const appStoreProps = {
    defaultProductImage: contentfulSettings.defaultProductImage.file.url,
    companyLogo: contentfulSettings.companyLogo.file.url,
    companyLogoColorful: contentfulSettings.companyLogoColorful.file.url,
    companyLogoColorfulAlt: contentfulSettings.companyLogoColorfulAlt.file.url,
    mobileAppLogo: contentfulSettings.mobileAppLogo.file.url,
    companyPhoneNumber: contentfulSettings.companyPhoneNumber,
    companyEmailAddress: contentfulSettings.companyEmailAddress,
    welcomeVideo: {
      src: contentfulSettings.welcomeVideo.video.file.url,
      thumbnail: contentfulSettings.welcomeVideo.thumbnail.file.url,
      title: contentfulSettings.welcomeVideo.title,
      description: contentfulSettings.welcomeVideo.description,
    },
    productsUnitsLimits: allContentfulProductUnitsLimitation.edges.map(({ node }) => node),
    loginBackgroundImageUrl: contentfulSettings.loginSettings.backgroundImage.file.url,
    loginLogo: contentfulSettings.loginSettings.logo.file.url,
  }

  return (
    <>
      <SiteHead />
      <ToastProvider>
        <AuthProvider>
          <LoadingProvider>
            <AppProvider {...appStoreProps}>
              <Shell>{props.children}</Shell>
            </AppProvider>
          </LoadingProvider>
        </AuthProvider>
      </ToastProvider>
    </>
  )
}
