import React from 'react'
import ReactSelect, { OptionProps, SingleValueProps, StylesConfig } from 'react-select'

import { useByCountry } from '../../i18n'
import { ZIndex } from '../../types'
import { EventCategory, sendGtagEvent } from '../../utils/analytics'
import Icon from '../atoms/Icon'
import { SelectOption, SelectProps } from './Select'

interface Item {
  label: string
  value: string | number
  deleted: boolean
}

interface StoreSelectorProps extends Omit<SelectProps, 'onSelect'> {
  placeholder?: string
  onOpen: () => void
  onClose: () => void
  zIndex?: ZIndex
  currentStore: SelectOption
  className?: string
  isChangingStore?: boolean
  onSelect: (value: Item) => void
}

const DropdownIndicator = ({ options }: any) => {
  if (options && options.length > 1)
    return (
      <Icon
        className="react-select-store-selector__dropwown-icon"
        id="chevron_down"
        size={24}
        color="white"
        viewBox="0 0 24 24"
        onClick={() => null}
      />
    )
  return null
}

const IndicatorSeparator = () => null

const SingleValue = ({
  data: { label, value },
  options,
  isChangingStore,
  ...props
}: SingleValueProps<any> & { isChangingStore: boolean }) => {
  if (isChangingStore && !!options?.length) {
    return <span className="text-white text-xs font-bold max-w-34 w-23/24">Cambiando...</span>
  }
  return (
    <div className="text-white font-bold leading-tight max-w-34 w-23/24" {...props}>
      <p style={{ fontSize: '13px' }} className="truncate">
        {label}
      </p>
      <p className="text-xs truncate">
        {useByCountry('ID', 'COD')}: {value}
      </p>
    </div>
  )
}

const Option = ({ data: { label, value }, isSelected, isFocused, innerRef, innerProps }: OptionProps<any, false>) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="cursor-pointer text-black border-0 px-4 p-1.5 text-sm leading-5"
      style={{
        backgroundColor: isSelected ? 'rgb(207,211,226)' : isFocused ? 'rgb(232, 235, 247)' : 'white',
      }}
    >
      <p>{label}</p>
      <p>
        {useByCountry('ID', 'COD')}: {value}
      </p>
    </div>
  )
}

const StoreSelector = (props: StoreSelectorProps) => {
  const {
    noOptionsMessage,
    placeholder,
    isClearable,
    onSelect,
    options = [],
    isLoading,
    onOpen,
    onClose,
    zIndex = 50,
    currentStore,
    className,
    isChangingStore,
  } = props

  const customStyles: StylesConfig = {
    menu: (provided) => ({
      ...provided,
      border: 0,
    }),
    input: (provided) => ({
      ...provided,
      border: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? 0 : 0,
      /*
      FIXME: https://stackoverflow.com/questions/52614304/react-select-remove-focus-border
      state.isFocused ? 0 : 0, is needed because aparently is the only way to remove blue borders (please review it)
     */
      boxShadow: state.isFocused ? ['0'] : ['0'],
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
      backgroundColor: 'transparent',
      padding: '0 8px',
      height: 'auto',
    }),
    container: (provided) => ({
      ...provided,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      zIndex,
      fontSize: '14px',
    }),
  }

  function handleChange(val: Item) {
    if (val) {
      return onSelect(val)
    }
  }

  const renderPlaceholder = () => {
    if (isLoading || options?.length === 0) {
      return <span className="text-white text-xs font-bold">Cargando...</span>
    }
    return placeholder
  }

  return (
    <div className={`${className ?? ''} desktop:flex`} title={`${currentStore?.label} (${currentStore?.value})`}>
      {options?.length === 1 ? (
        <div
          className={`rounded border-1.5 border-white text-white text-left font-bold leading-tight w-auto inline-block desktop:ml-auto px-4 py-1 max-w-full`}
        >
          <p style={{ fontSize: '13px' }} className="truncate">
            {currentStore?.label}
          </p>
          <p className="text-xs truncate">
            {useByCountry('ID', 'COD')}: {currentStore?.value}
          </p>
        </div>
      ) : (
        <div className="rounded w-full border-1.5 border-white">
          <ReactSelect
            value={options ? currentStore : null}
            isLoading={isLoading || options?.length === 0 || (isChangingStore && !!options?.length)}
            options={options}
            styles={customStyles}
            className="react-select-store-selector-container z-40"
            classNamePrefix="react-select-store-selector"
            components={{
              DropdownIndicator,
              IndicatorSeparator,
              SingleValue: (props) => SingleValue({ ...props, isChangingStore: isChangingStore ?? false }),
              Option,
            }}
            noOptionsMessage={() => noOptionsMessage ?? 'No hay locales'}
            isClearable={isClearable}
            menuPlacement="auto"
            placeholder={renderPlaceholder()}
            onMenuOpen={onOpen}
            onMenuClose={onClose}
            isSearchable={false}
            isDisabled={options.length <= 1}
            onChange={(val) => {
              sendGtagEvent({
                eventCategory: EventCategory.Layout,
                eventAction: 'Cambio de Local',
                eventLabel: 'Navbar',
              })
              return handleChange(val as Item)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default StoreSelector
