import React, { useEffect, useState } from 'react'

import Icon, { IconProps } from '../atoms/Icon'

const ratio = 0.8

interface CartIconProps {
  quantity?: number
  size?: number
  color?: string
  className?: string
  idContainer?: string
}

/*
 * FIXME:
 *  - rename cart icon id to better. Ex: cart_0 -> empty_cart
 * */

/* cart 0 = carro vacio
 * cart 1 = carro con numero 1
 * cart 2 = carro CON circulo de NUMERO pero EN BLANCO
 * cart 3 = carro con numero en 99
 * cart 4 = carro con numero en +99
 * */
const CartIcon = (props: CartIconProps) => {
  const [showQuantity, setShowQuantity] = useState(true)
  const [cartIconId, setCartIconId] = useState<IconProps['id']>('cart_0')
  // const [iconSize, setIconSize] = useState(props.size ? props.size * ratio : 32 * ratio)
  const iconSize = (props.size ? props.size : 32) * ratio

  useEffect(() => {
    if (!props.quantity || props.quantity < 0) {
      setCartIconId('cart_0')
      setShowQuantity(false)
      // setIconSize(iconSize * ratio)
    }
    if (props.quantity && props.quantity > 1 && props.quantity < 99) {
      setCartIconId('cart_2')
      setShowQuantity(true)
    }
    if (props.quantity && props.quantity === 1) setCartIconId('cart_1')
    if (props.quantity && props.quantity >= 99) setShowQuantity(false)
    if (props.quantity && props.quantity === 99) setCartIconId('cart_3')
    if (props.quantity && props.quantity > 99) setCartIconId('cart_4')
  }, [props.quantity])

  return (
    <div id={props.idContainer} className={props.className} style={{ width: 32, height: 32 }}>
      <Icon id={cartIconId} {...props} size={iconSize} color={props.color} />
      {showQuantity && (
        <div
          className="absolute top-4 font-bold flex justify-end items-start text-primary"
          style={{ width: iconSize, height: iconSize / ratio, fontSize: iconSize / 2.6 }}
        >
          <span
            className="text-center text-primary select-none cart-icon-text"
            style={{ width: iconSize / 2, marginTop: '-1px' }}
          >
            {props.quantity}
          </span>
        </div>
      )}
    </div>
  )
}

export default CartIcon
