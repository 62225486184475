import axios from 'axios'

import networkErrorHandler from './networkErrorHandler'

const errors = {
  NETWORK_ERROR: { status: null, code: 'NETWORK_ERROR', meta: null, description: 'Network error' },
  NOT_FOUND_ERROR: { status: 404, code: 'NOT_FOUND_ERROR', meta: null, description: 'Resource not found' },
  UNAUTHORIZED_ERROR: { status: 401, code: 'UNAUTHORIZED_ERROR', meta: null, description: 'Unauthorized request' },
  FORBIDDEN_ERROR: { status: 403, code: 'FORBIDDEN RESOURCE', meta: null, description: 'Forbidden resource' },
  REQUEST_ERROR: { status: 400, code: 'REQUEST ERROR', meta: null, description: 'Request error' },
  PASSWORD_UPDATE_ERROR: {
    status: 409,
    code: 'PASSWORD_UPDATE_ERROR',
    meta: null,
    description: 'User needs password update',
  },
  REQUEST_TIMEOUT: {
    status: 408,
    code: 'REQUEST TIMEOUT',
    meta: null,
    description: 'Request timeout',
  },
  UNPROCESSABLE_ENTITY: {
    status: 422,
    code: 'UNPROCESSABLE_ENTITY',
    meta: null,
    description: 'Unprocessable Request',
  },
}

export const flatError = (err: any) => {
  switch (err) {
    case 400: {
      throw errors.REQUEST_ERROR
    }
    case 404: {
      throw errors.NOT_FOUND_ERROR
    }
    case 409: {
      throw errors.PASSWORD_UPDATE_ERROR
    }
    case 401: {
      throw errors.UNAUTHORIZED_ERROR
    }
    case 403: {
      throw errors.FORBIDDEN_ERROR
    }
    case 408: {
      throw errors.REQUEST_TIMEOUT
    }
    case 422: {
      throw errors.UNPROCESSABLE_ENTITY
    }
    default: {
      throw errors.NETWORK_ERROR
    }
  }
}

export const handleServiceError = async (error) => {
  if (error.response) {
    return flatError(error.response.status)
  } else if (
    error.request &&
    ((error.code !== 'ECONNABORTED' && !axios.isCancel(error)) ||
      (error.code === 'ECONNABORTED' && error.message?.includes('timeout')))
  ) {
    await new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await networkErrorHandler(location.pathname ?? window.location.pathname ?? '/'))
      }, 2500)
    })
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
}
