import React, { useMemo } from 'react'

import { ToastTypes } from '../../contexts'
import Icon, { IconProps } from '../atoms/Icon'

export enum ToastSizes {
  NORMAL = 'normal',
  SMALL = 'small',
}

interface ToastCardProp extends React.HTMLAttributes<HTMLDivElement> {
  message?: React.ReactNode
  iconId?: IconProps['id']
  iconBgColor?: string
  size?: ToastSizes
  type?: ToastTypes
}

function ToastCard({ message, iconId, iconBgColor, size = ToastSizes.NORMAL, type, ...restProps }: ToastCardProp) {
  const renderToast = useMemo(() => {
    if (type === ToastTypes.FAVORITE) {
      return (
        <div {...restProps} className="flex justify-center">
          <div className="bg-white flex items-center rounded-5 shadow">
            {iconId && (
              <div className={`${iconBgColor} p-2.5 rounded-l-5`}>
                <Icon id={iconId} color="#F40009" size={24} />
              </div>
            )}
            <p className="text-xs text-text-display-secondary px-2">{message}</p>
          </div>
        </div>
      )
    }

    if (size === ToastSizes.SMALL) {
      return (
        <div {...restProps} className="shadow flex z-100 w-44 transition-opacity ml-auto">
          <div className="flex h-full w-full bg-white rounded">
            {iconId && (
              <div className={`rounded ${iconBgColor} flex items-center py-1 px-2`}>
                <Icon id={iconId} color="white" size={24} />
              </div>
            )}
            <div className="px-3 py-2">
              <p className="text-xs">{message}</p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div {...restProps} className="flex mx-auto w-auto max-w-84 tablet:max-w-128 px-4 justify-center">
        <div className={`flex ${iconBgColor} rounded px-4 py-2 items-center shadow-lg min-h-10`}>
          {iconId && (
            <div className={`${iconBgColor} flex items-center  pr-2`}>
              <Icon id={iconId} color="white" size={24} />
            </div>
          )}
          <div>
            <p className="text-sm text-white">{message}</p>
          </div>
        </div>
      </div>
    )
  }, [size, message, type])

  return renderToast
}

export default ToastCard
