import { navigate } from 'gatsby'

/**
 * Handle network errors and redirect
 * @param error
 */
const networkErrorHandler = async (originPath?: string): Promise<void> => {
  const errorOrigin = originPath ?? location.pathname ?? window.location.pathname ?? '/'
  if (errorOrigin.includes('/network-error') || errorOrigin.includes('/checkout')) return

  await navigate(`/network-error`, { state: { errorOrigin } })
}

export default networkErrorHandler
