import React, { ImgHTMLAttributes, useEffect, useState } from 'react'

import { useAppStore } from '../../contexts'
import { isBrowser } from '../../utils/ssr'

type ImagesProps = ImgHTMLAttributes<HTMLImageElement> & {
  preload?: boolean
  preloadSize?: { width?: number; height?: number }
}

function Image({ src, alt, preload, className, preloadSize, ...othersProps }: ImagesProps) {
  if (!isBrowser) return <img src={src} alt={alt} {...othersProps} />

  const {
    state: { defaultProductImage },
  } = useAppStore()

  const [imageSrc, setImageSrc] = useState(preload ? `${src?.split('?')[0]}?h=4` : src)
  const [isBlurred, setIsBlurred] = useState(!!preload)

  useEffect(() => {
    if (!src) {
      setImageSrc(defaultProductImage)
      setIsBlurred(false)
      return
    }

    if (preload && isBlurred) {
      fetch(`${src}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          setImageSrc(url)
        })
        .catch(() => setImageSrc(src))
        .finally(() => setIsBlurred(false))
    }
  }, [])

  return (
    <img
      src={imageSrc}
      style={
        preload && preloadSize ? { width: preloadSize.width || undefined, height: preloadSize.height || undefined } : {}
      }
      alt={alt}
      {...othersProps}
      onError={() => {
        setImageSrc(defaultProductImage)
      }}
      className={`product-image ${isBlurred ? 'blurred' : ''} ${className}`}
    />
  )
}

export default Image
