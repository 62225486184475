import React from 'react'
import ReactSelect, { StylesConfig } from 'react-select'

import FieldFeedback from '../atoms/FieldFeedback'
import Icon from '../atoms/Icon'

type SelectValue = string | number

export type SelectOption = {
  value: SelectValue
  label: string
}

export interface SelectProps {
  className?: string
  wrapperClassName?: string
  classNamePrefix?: string
  error?: boolean
  isClearable?: boolean
  isLoading?: boolean
  label?: string
  message?: string
  name?: string
  isSearchable?: boolean
  noOptionsMessage?: string
  onChange?: () => void
  onSelect: (value: SelectOption) => void
  options?: SelectOption[]
  placeholder?: string
  ref?: any
  disabled?: boolean
  hideArrow?: boolean
  value?: SelectOption
  iconColor?: string
  styles?: StylesConfig<SelectOption>
}

const DropdownIndicator = ({ color }: { color?: string }) => (
  <Icon
    className="react-select__dropwown-icon"
    color={color ?? '#808080'}
    size={24}
    id="chevron_down"
    onClick={() => null}
    viewBox="0 0 24 24"
  />
)

const IndicatorSeparator = () => null

const defaultStyles: StylesConfig<SelectOption> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgb(232, 235, 247)' : 'white',
    color: 'black',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? 'rgba(95, 111, 134, 0.1)' : provided.backgroundColor,
    borderColor: state.menuIsOpen ? 'rgb(232, 235, 247)' : state.isDisabled ? 'transparent' : 'white',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
}

/*
 * FIXME:
 *  - Add classname into ReactSelect
 *  - Should be exist a props that allows change size of the component
 * */

const Select = (props: SelectProps) => {
  const {
    noOptionsMessage,
    placeholder,
    isClearable,
    label,
    message,
    isSearchable,
    error,
    onSelect,
    options,
    value,
    isLoading,
    disabled,
    className = '',
    hideArrow,
    wrapperClassName,
    iconColor,
    styles = {},
  } = props

  return (
    <div className={`min-w-full ${wrapperClassName ?? 'mb-5'}`}>
      <label className="left text-sm">{label}</label>
      <ReactSelect
        value={value}
        isSearchable={isSearchable ?? false}
        isDisabled={disabled}
        isLoading={isLoading}
        loadingMessage={() => 'Cargando...'}
        options={options}
        styles={{ ...defaultStyles, ...styles }}
        className={`${className} react-select-container base-select`}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: hideArrow ? null : () => <DropdownIndicator color={iconColor} />,
          IndicatorSeparator,
        }}
        noOptionsMessage={() => noOptionsMessage ?? 'No hay opciones disponibles'}
        isClearable={isClearable}
        menuPlacement="auto"
        placeholder={placeholder}
        onChange={(val) => {
          if (val) {
            return onSelect(val as SelectOption)
          }
        }}
      />
      {message ? <FieldFeedback error={error} message={message} /> : null}
    </div>
  )
}

export default Select
