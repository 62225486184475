import {
  BundlePrice,
  Cart,
  CartBundle,
  CartProduct,
  CartProducts,
  CartSummary,
  ProductPrice,
} from '../../contexts/Cart/types'
import { handleServiceError } from '../../utils/errors'
import { reportError, Severity } from '../../utils/sentry'
import { fetch } from '../clients/bff'

const headers = { Accept: 'application/json', 'Content-Type': 'application/json' }

const baseUrl = 'cart'

export type ProductInputPrice = {
  productId: number
  isDutyFree: boolean
  boxAmount?: number
  bottleAmount?: number
}

export type BundleInputPrice = {
  bundleId: number
  bundleAmount: number
  products: Omit<ProductInputPrice, 'isDutyFree'>[]
}

export const getCustomerCart = async (storeId: string): Promise<Cart> => {
  try {
    const { data } = await fetch(`${baseUrl}/${storeId}`, { headers, method: 'GET' })

    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at getting customer cart. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const emptyCart = async (storeId: string): Promise<boolean> => {
  try {
    await fetch(`${baseUrl}/${storeId}`, { headers, method: 'DELETE' })
    return true
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred empty customer cart. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

interface CreatedRepeatedCartProps {
  storeId: string
  products: CartProducts
  isRepeatedFrom: number
}

export const createRepeatedCart = async ({
  storeId,
  products,
  isRepeatedFrom,
}: CreatedRepeatedCartProps): Promise<Cart> => {
  try {
    const { data } = await fetch(`${baseUrl}/${storeId}`, {
      headers,
      method: 'POST',
      data: { isRepeatedFrom, products },
    })
    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred creating cart from order. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const addCartProduct = async ({
  storeId,
  product,
}: {
  storeId: string
  product: CartProduct
}): Promise<Cart> => {
  try {
    const { sku, ..._product } = product
    const { data } = await fetch(`${baseUrl}/${storeId}/${sku}`, {
      headers,
      method: 'POST',
      data: _product,
    })
    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred adding product to cart. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const replaceProduct = async ({
  storeId,
  product,
}: {
  storeId: string
  product: CartProduct
}): Promise<boolean> => {
  try {
    const { sku, ..._product } = product
    await fetch(`${baseUrl}/${storeId}/${sku}`, {
      headers,
      method: 'PATCH',
      data: _product,
    })
    return true
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred replacing cart product. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const deleteProduct = async ({ storeId, sku }: { storeId: string; sku: string }): Promise<boolean> => {
  try {
    await fetch(`${baseUrl}/${storeId}/${sku}`, {
      headers,
      method: 'DELETE',
    })
    return true
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred deleting cart product. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const addCartBundle = async ({ storeId, bundle }: { storeId: string; bundle: CartBundle }): Promise<Cart> => {
  try {
    const { sku, bundleQuantity, products } = bundle
    const { data } = await fetch(`${baseUrl}/${storeId}/bundle/${sku}`, {
      headers,
      method: 'POST',
      data: { bundleQuantity, products },
    })
    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred adding product to cart. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const replaceBundle = async ({
  storeId,
  sku,
  bundleQuantity,
}: {
  storeId: string
  sku: number
  bundleQuantity: number
}): Promise<boolean> => {
  try {
    await fetch(`${baseUrl}/${storeId}/bundle/${sku}`, {
      headers,
      method: 'PATCH',
      data: { bundleQuantity },
    })
    return true
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred replacing cart product. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const deleteBundle = async ({ storeId, sku }: { storeId: string; sku: string }): Promise<boolean> => {
  try {
    await fetch(`${baseUrl}/${storeId}/bundle/${sku}`, {
      headers,
      method: 'DELETE',
    })
    return true
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred deleting cart product. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const getCustomerCartSummary = async (storeId: string): Promise<CartSummary> => {
  try {
    const { data } = await fetch(`${baseUrl}/cartsummary/${storeId}`, { headers, method: 'GET' })

    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at getting cart summary. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}

export const getProductPrices = async ({
  storeId,
  products,
  bundles,
  branchOfficeId,
}: {
  storeId: string
  products?: ProductInputPrice[]
  bundles?: BundleInputPrice[]
  branchOfficeId?: string
}): Promise<{ products: ProductPrice[]; bundles?: BundlePrice[] }> => {
  try {
    const { data } = await fetch(`${baseUrl}/prices/${storeId}`, {
      headers,
      method: 'POST',
      data: { products: products ?? [], bundles, branchOfficeId },
    })
    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at getting product prices. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }
    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
    })

    return Promise.resolve(handleServiceError(error))
  }
}
