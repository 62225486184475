import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import Icon, { IconProps } from '../atoms/Icon'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  title: string
  iconId?: IconProps['id']
  children: React.ReactNode | React.ReactNode[]
  isOpen?: boolean
  onClick?: () => void
  onOpen?: () => void
  customMaxHeight?: number
  rounded?: boolean
  onClickHelpSection?: () => void
  headerClassName?: string
}

const Collapse = ({
  title,
  children,
  iconId,
  isOpen = false,
  onClick,
  onOpen,
  className = '',
  customMaxHeight,
  rounded = true,
  onClickHelpSection,
  headerClassName,
  ...restProps
}: Props) => {
  const [isShown, setIsShown] = useState(isOpen)
  const [maxHeight, setMaxHeight] = useState(customMaxHeight ?? 0)

  const ref = useRef<HTMLElement>(null)

  const handleMaxHeight = () => {
    if (!customMaxHeight) setMaxHeight((ref.current?.clientHeight ?? 0) + 100)
  }
  const handleClick = () => {
    if (!isShown) onOpen?.()
    onClickHelpSection?.()
    return setIsShown((prev) => !prev)
  }

  useEffect(() => setIsShown(isOpen), [isOpen])
  useEffect(handleMaxHeight, [children, customMaxHeight])

  const overflow = isShown ? 'visible' : 'hidden'

  return (
    <div {...restProps} className={`${className} w-full`}>
      <div
        onClick={onClick ?? handleClick}
        className={` cursor-pointer relative bg-medium-50 p-5 font-lg flex items-center ${
          rounded ? 'rounded-tr rounded-tl' : ''
        } ${headerClassName ?? 'p-5'}
        `}
      >
        {iconId && <Icon className="mr-3" id={iconId} color="black" size={22} />}
        {title}
        <button type="button" className="ml-auto block pl-3 focus:outline-none">
          <Icon size={22} color="black" id={isShown ? 'minus' : 'add'} />
        </button>
      </div>
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ overflow, maxHeight: isShown ? `${maxHeight}px` : '0px', opacity: isShown ? 1 : 0 }}
        animate={{
          maxHeight: isShown ? `${maxHeight}px` : '0px',
          opacity: isShown ? 1 : 0,
          overflow,
        }}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement, { ref })
          }

          return null
        })}
      </motion.div>
    </div>
  )
}

export default Collapse
