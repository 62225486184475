import React, { useEffect, useState } from 'react'

import Checkbox from '../../atoms/Checkbox'
import TableRow from './TableRow'

export interface TableRowContent {
  content: React.ReactNode[]
  value?: string
}

interface TableProps {
  headings: string[]
  rows: TableRowContent[]
  columnWidths?: string[]
  className?: string
  wrapperClassname?: string
  headingsClassname?: string
  rowsClassname?: string
  selectedRowsClassName?: string
  selectable?: boolean
  rowValign?: 'baseline' | 'bottom' | 'middle' | 'top'
  values?: string[]
  onChange?: (values: string[]) => void
}

const Table = ({
  headings,
  rows,
  className,
  wrapperClassname,
  selectable,
  onChange,
  values: parentValues,
  columnWidths,
  headingsClassname,
  rowsClassname,
  selectedRowsClassName,
  rowValign = 'top',
}: TableProps) => {
  const [values, setValues] = useState<string[]>(parentValues ?? [])
  const [isWholeSelection, setIsWholeSelection] = useState(false)

  useEffect(() => {
    if (parentValues) {
      setValues(parentValues)

      if (parentValues.length >= rows.length) {
        setIsWholeSelection(true)
      } else {
        setIsWholeSelection(false)
      }
    }
  }, [parentValues])

  return (
    <div className={wrapperClassname ?? ''}>
      <table className={className ?? 'table-fixed'}>
        <thead className="rounded-t">
          <tr>
            {selectable && (
              <th
                className={`first:pl-3 desktop:first:pl-10  last:pr-3 desktop:last:pr-10 py-3 ${
                  headingsClassname ?? ''
                }`}
                style={{
                  width: '16px',
                  height: '16px',
                  paddingRight: '8px',
                  boxSizing: 'content-box',
                }}
              >
                <div style={{ marginTop: '-0.5px' }}>
                  <Checkbox
                    value={'all'}
                    onChange={() => {
                      if (!isWholeSelection) {
                        const allValues = rows.map((row) => row.value ?? '')
                        setValues(allValues)
                        onChange?.(allValues)
                        setIsWholeSelection(true)
                        return
                      }
                      setValues([])
                      onChange?.([])
                      setIsWholeSelection(false)
                    }}
                    checked={isWholeSelection}
                  />
                </div>
              </th>
            )}

            {headings.map((heading, i) => {
              if (headings.length === i + 1)
                return (
                  <th
                    key={i}
                    className={
                      headingsClassname ||
                      'text-dark text-xs desktop:text-sm text-left first:pl-3 desktop:first:pl-10 px-1 desktop:px-4 last:pr-3 desktop:last:pr-10 py-3'
                    }
                    style={{ width: columnWidths?.[i] || 'auto' }}
                  >
                    {heading}
                  </th>
                )
              return (
                <th
                  key={i}
                  className={
                    headingsClassname ||
                    'text-dark text-xs desktop:text-sm text-left first:pl-3 desktop:first:pl-10 px-1 desktop:px-4 last:pr-3 desktop:last:pr-10 py-3'
                  }
                >
                  {heading}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <TableRow
              valign={rowValign}
              content={row.content}
              selectable={selectable}
              className={rowsClassname}
              selectedClassName={selectedRowsClassName}
              key={row.value}
              value={row.value}
              isActive={values.includes(row.value ?? '')}
              onSelect={(value) => {
                if (value) {
                  const newValues = [...values]
                  newValues.push(value)
                  setValues(newValues)
                  onChange?.(newValues)

                  if (newValues.length === rows.length) {
                    setIsWholeSelection(true)
                  } else {
                    setIsWholeSelection(false)
                  }
                }
              }}
              onUnselect={(value) => {
                if (value) {
                  const newValues = [...values].filter((selectedValue) => selectedValue !== value)
                  setValues(newValues)
                  onChange?.(newValues)
                  setIsWholeSelection(false)
                }
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
