import React, { useEffect } from 'react'

import { isBrowser } from '../utils/ssr'

function createRootElement(id) {
  const rootElement = document.createElement('div')
  rootElement.setAttribute('id', id)
  return rootElement
}

function addRootElement(rootElem: Element) {
  if (!document.body.lastElementChild) throw Error('Body has no childs')

  document.body.insertBefore(rootElem, document.body.lastElementChild?.nextElementSibling)
}

export default (args: { id: string }) => {
  if (!isBrowser) return

  const elementRef = React.useRef<Element | null>(null)

  function getElementRef() {
    if (elementRef.current === null) {
      elementRef.current = document.createElement('div')
    }
    return elementRef.current
  }

  useEffect(() => {
    const existingParent = document.querySelector(`#${args.id}`)

    const parentElement = existingParent || createRootElement(args.id)

    if (!existingParent) {
      addRootElement(parentElement)
    }

    parentElement.appendChild(getElementRef())

    return function cleanup() {
      getElementRef().remove()

      if (!parentElement.childElementCount) {
        parentElement.remove()
      }
    }
  }, [args.id])

  return getElementRef()
}
