import Proptypes from 'prop-types'
import React from 'react'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  label?: string | React.ReactNode
  containerClassName?: string
}
type Ref = HTMLInputElement

const Checkbox = React.forwardRef<Ref, Props>(
  ({ label, containerClassName = '', className = '', ...restProps }, ref) => (
    <div className={`flex ${containerClassName}`}>
      <input
        {...restProps}
        style={{ boxShadow: 'none' }}
        className={`${className} ${
          label ? 'mr-2' : ''
        } rounded text-primary border-primary border-2 p-0 w-5 h-5 max-w-5 max-h-5 cursor-pointer`}
        ref={ref}
        type="checkbox"
      />
      {typeof label === `string` ? <p className="text-sm leading-4">{label}</p> : label}
    </div>
  ),
)

Checkbox.propTypes = {
  className: Proptypes.string,
  containerClassName: Proptypes.string,
  label: Proptypes.oneOfType([Proptypes.string, Proptypes.node]),
}

export default Checkbox
