import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React, { ReactNode, useState } from 'react'

import { useAppStore } from '../../../contexts'
import { EventCategory, sendGtagEvent } from '../../../utils/analytics'
import { CartIcon, Icon, StoreSelector, TabsGroup } from '../../'
import Overlay from '../../molecules/Overlay'
import ConfirmationAlert from '../ConfirmationAlert'
import ModalDeleted from './ModalDeleted'
import { ChangeStoreAlertType, useChangeStoreConfimation } from './useChangeStoreConfirmation'

export interface TabProps {
  label?: string
  icon?: React.ReactNode
  isActive?: boolean
  onClick?: () => void
}

interface Item {
  label: string
  value: string | number
  deleted: boolean
}

interface NavbarProps {
  stores: Item[]
  currentStore: Item
  onSelectStore: (value: Item) => void | Promise<void>
  isLoadingStoreSelectorOptions: boolean
  menuContentComponent: ReactNode
  cartQuantity?: number
  name: string
  fixed?: boolean
  leftSectionComponent: ReactNode
  tabs: TabProps[]
  isOpenMenu: boolean
  onOpenMenu: () => void
  onCloseMenu: () => void
}

const Navbar = (props: NavbarProps) => {
  const {
    leftSectionComponent,
    stores,
    currentStore,
    isLoadingStoreSelectorOptions,
    menuContentComponent,
    onSelectStore,
    cartQuantity,
    fixed,
    name = '',
    isOpenMenu,
    onCloseMenu,
    onOpenMenu,
  } = props

  const [isOpenStoreSelector, setIsOpenStoreSelector] = useState(false)
  const [isOpenModalDeleted, setIsOpenModalDeleted] = useState(false)
  const [deletedStore, setDeletedStore] = useState<Item | undefined>(undefined)

  const location = useLocation()
  const { state: changeStoreConfirmationState, triggerStoreConfirmationAlert } = useChangeStoreConfimation()
  const {
    state: { isLoadingCurrentStore, stores: userStores },
  } = useAppStore()

  function handleOpenMenu() {
    onOpenMenu()
  }

  function handleCloseMenu() {
    onCloseMenu()
  }

  function handleOpenStoreSelector() {
    setIsOpenStoreSelector(true)
  }

  function handleCloseStoreSelector() {
    setIsOpenStoreSelector(false)
  }

  function handleSelectStore(value: Item) {
    if (!userStores || !userStores.find((store) => store.id === value.value)) return

    const shortStore = userStores.find((store) => store.id === value.value)

    if (!shortStore) return

    setIsOpenStoreSelector(false)

    if (value.deleted) {
      setDeletedStore(value)
      setIsOpenModalDeleted(true)

      return
    }

    if (
      location.pathname.includes('/app/shopping-cart') ||
      location.pathname.includes('/app/promotions') ||
      location.pathname.includes('/app/products')
    ) {
      return triggerStoreConfirmationAlert(shortStore, ChangeStoreAlertType.PRODUCTS, async () => {
        await onSelectStore(value)
        window.scrollTo({ top: 0 })
      })
    }

    return triggerStoreConfirmationAlert(shortStore, ChangeStoreAlertType.ACCOUNT, async () => {
      await onSelectStore(value)
      window.scrollTo({ top: 0 })
    })
  }

  return (
    <>
      <ConfirmationAlert {...changeStoreConfirmationState} />
      {deletedStore && (
        <ModalDeleted
          isOpen={isOpenModalDeleted}
          onClose={() => setIsOpenModalDeleted(false)}
          deletedStore={deletedStore}
        />
      )}
      <Overlay zIndex={10} isActive={false && isOpenStoreSelector} />
      <div
        className={`w-full ${
          fixed ? 'sticky top-0 left-0' : ''
        }  shadow-lg z-50 bg-primary flex h-14 tablet:h-18 desktop:h-16`}
      >
        <div
          className={`w-full h-full fixed z-60 top-0 left-0 bg-black bg-opacity-40 ${isOpenMenu ? '' : 'hidden'}`}
          onClick={handleCloseMenu}
        />
        <div className="container flex justify-between">
          <div className="">{leftSectionComponent}</div>
          <div className="flex justify-center items-center text-white">
            <TabsGroup tabs={props.tabs} className="hidden desktop:flex h-full" />
            <div className="desktop:hidden">
              <StoreSelector
                onSelect={handleSelectStore}
                onOpen={handleOpenStoreSelector}
                onClose={handleCloseStoreSelector}
                currentStore={currentStore}
                options={stores}
                isLoading={isLoadingStoreSelectorOptions}
                isChangingStore={isLoadingCurrentStore}
                name="defaultStoreId"
                zIndex={20}
                className="w-40"
              />
            </div>
          </div>
          <div className="flex justify-end items-center">
            <div className="hidden text-right desktop:flex desktop:w-full desktop:ml-auto desktop:justify-end">
              <StoreSelector
                onSelect={handleSelectStore}
                onOpen={handleOpenStoreSelector}
                onClose={handleCloseStoreSelector}
                options={stores}
                currentStore={currentStore}
                isChangingStore={isLoadingCurrentStore}
                isLoading={isLoadingStoreSelectorOptions}
                name="defaultStoreId"
                zIndex={20}
                className="w-40 text-left"
              />
            </div>

            <div className="relative justify-center items-center hidden desktop:block cursor-pointer">
              <div
                className="h-10 w-10 flex justify-center items-center bg-white rounded-full text-primary font-extrabold select-none ml-6 mr-8"
                onClick={() => handleOpenMenu()}
              >
                <span className="text-3xl leading-4 h-auto inline-block">{name.charAt(0).toUpperCase()}</span>
              </div>

              <div
                className={`absolute bg-white z-70 top-0 py-4 rounded shadow-xl desktop:right-0 desktop:mt-9 ${
                  isOpenMenu ? '' : 'hidden'
                }`}
              >
                {menuContentComponent}
              </div>
            </div>
            <div
              className="h-full w-auto flex justify-center items-center mr-4 tablet:mr-6 desktop:mr-8"
              onClick={() => {
                sendGtagEvent({
                  eventCategory: EventCategory.Layout,
                  eventAction: `Ir a Ayuda`,
                  eventLabel: 'Navbar',
                })
                return navigate('/app/support')
              }}
            >
              <Icon id="help" size={28} color="white" className="cursor-pointer" />
            </div>
            <div
              className="h-full w-auto flex justify-center items-center"
              onClick={() => {
                sendGtagEvent({
                  eventCategory: EventCategory.Layout,
                  eventAction: `Ir a Carro`,
                  eventLabel: 'Navbar',
                })
                return navigate('/app/shopping-cart')
              }}
            >
              <CartIcon
                size={36}
                quantity={cartQuantity ?? 0}
                color="white"
                className="cursor-pointer"
                idContainer="onboarding-products-5"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
