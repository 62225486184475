import React from 'react'

import Icon, { IconProps } from './Icon'

interface FieldFeedbackProps {
  error?: boolean
  icon?: IconProps['id']
  message: string | React.ReactNode
  className?: string
}

const FieldFeedback = (props: FieldFeedbackProps) => {
  const containerClassName = `${props?.className} text-sm mt-1 flex ${
    props.error ? 'text-error' : 'base-input-help-text items-center'
  }`
  const iconId = props.icon ?? (props.error ? 'warning_fill' : 'info_fill')

  return (
    <div className={containerClassName}>
      <Icon id={iconId} onClick={() => null} size={19} className="mr-1 min-w-5 min-h-5" />
      {props.message}
    </div>
  )
}

export default FieldFeedback
