import React, { useEffect, useState } from 'react'

import { Icon } from '..'

interface Props {
  activationPosition?: number
  className?: string
}

const GoBackUpButton = ({ activationPosition, className }: Props) => {
  const [isActive, setIsActive] = useState(false)
  const [show, setShow] = useState(false)

  const onClick = () => {
    window?.scrollTo?.({ top: 0, behavior: 'smooth' })
  }

  const handleActivation = () => {
    setIsActive(true)
    setTimeout(() => {
      setShow(true)
    }, 150)
  }

  const handleDeactivation = () => {
    setShow(false)
    setTimeout(() => {
      setIsActive(false)
    }, 150)
  }

  const toggleActivation = () => {
    if (window?.scrollY > (activationPosition || 650) && !isActive) {
      handleActivation()
    } else if (window?.scrollY <= (activationPosition || 650) && isActive) {
      handleDeactivation()
    }
  }

  useEffect(() => {
    window?.addEventListener?.('scroll', toggleActivation)

    return () => {
      window?.removeEventListener?.('scroll', toggleActivation)
    }
  }, [isActive])

  return (
    <button
      className={`${
        className ?? 'z-20 fixed  left-0 bottom-40 desktop:bottom-4 desktop:left-auto'
      } bg-black-95 hover:bg-black-75  text-white  rounded-r-full py-3 px-6  desktop:rounded-full flex items-center outline-none focus:outline-none  ${
        isActive ? '' : 'hidden'
      } transition-opacity duration-150 ${show ? 'opacity-100' : 'opacity-0'}`}
      onClick={onClick}
      disabled={!isActive}
    >
      <span className="mr-2 desktop:mr-4 -mb-1 font-semibold ">Volver arriba</span>
      <Icon id="double_chevron_up" size="24" />
    </button>
  )
}

export default GoBackUpButton
