import { AppState } from '../../contexts/App/types'
import { FavoriteProduct, ShortStore } from '../../types'
import { handleServiceError } from '../../utils/errors'
import { ErrorSource, reportError, Severity } from '../../utils/sentry'
import { createAlgoliaGlobalFilters } from '../../utils/strings'
import { getProducts } from '../algolia'
import { fetch as fetchAuthentication } from '../clients/authentication'
import { fetch as fetchBFF } from '../clients/bff'

const headers = { Accept: 'application/json', 'Content-Type': 'application/json' }

export interface GetUserParams {
  id: string
}

export interface GetUserSignUpDataParams {
  userId: string
}

export interface UpdateUserParams {
  userId: string
  dni?: string
  phone?: string
  defaultStoreId: number
  dutyFreeStores?: number[]
}

export interface GetCreditInfoParams {
  customerId: string
}

export interface GetUserRelatedStoresParams {
  storeIds: number[]
  defaultStoreId: string
}

export interface GetUserDefaultStoreParams {
  id: string
  dutyFreeStores?: number[]
}

export interface AddStoreFavoriteProduct {
  id: number
  product: FavoriteProduct
}

export interface AddStoreMultipleFavoriteProduct {
  id: number
  products: number[]
}

export interface DeleteStoreFavoriteProduct {
  id: number
  productId: number
}

export interface SignUpParams {
  userId: string
  dni?: string
  name: string
  phone: string
  password: string
  defaultStoreId: number
  dutyFreeStores?: number[]
}

export interface AccessRecoveryParams {
  username: string
  successUrl: string
  failureUrl: string
}

export interface ChangePasswordParams {
  userId: string
  secretCode: string
  password: string
}

export interface SendEmailChangeRequestParams {
  userId: string
  newEmail: string
}

export interface VerifyEmailChangeRequestCodeParams {
  userId: string
  code: string
}

export interface VerifyCorrectPasswordParams {
  password: string
}
export interface SendPasswordChangeRequestParams {
  userId: string
  newPassword: string
}

export interface Onboarding {
  mandatoryModal: boolean
  products: boolean
  cart: boolean
  checkout: boolean
}

export type VerifyPasswordChangeRequestCodeParams = VerifyEmailChangeRequestCodeParams

const getUserInterpreter = async (params: GetUserParams) => {
  try {
    const { data } = await fetchAuthentication(`/users/${params.id}`, { headers, method: 'GET' })
    return data
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}

export const getUser = async (params: GetUserParams): Promise<any> => {
  return getUserInterpreter(params)
}

export const updateUser = async (params: UpdateUserParams): Promise<any> => {
  try {
    const { dni, defaultStoreId, userId, phone, dutyFreeStores } = params

    return await fetchBFF(`/users/${userId}`, {
      data: { dni, phone, defaultStoreId, dutyFreeStores },
      headers,
      method: 'PATCH',
    })
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at updateUser. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }

    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
      page: '/signup',
      source: ErrorSource.AUTH_SERVICE,
    })

    console.error('Error at completing user update process.')
    return Promise.resolve(handleServiceError(error))
  }
}

export const updateUserOnboarding = async (params: { userId: string; onboarding: Onboarding }): Promise<any> => {
  try {
    const { userId, onboarding } = params

    const { data } = await fetchBFF(`/users/${userId}/update-onboarding`, {
      headers,
      method: 'POST',
      data: onboarding,
    })

    return data
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at updateUserOnboarding. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }

    reportError({
      message: errorInfo.message,
      level: Severity.Error,
      metadata: { error: errorInfo, response: errorInfo.response },
      page: '/app',
      source: ErrorSource.AUTH_SERVICE,
    })

    console.error('Error at completing update onboarding process.')
    return Promise.resolve(handleServiceError(error))
  }
}

export const getUserSignUpData = async (params: GetUserSignUpDataParams): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/users/${params.userId}`, { headers, method: 'GET' })

    return data
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}

export const getUserStores = async (
  params: GetUserRelatedStoresParams,
): Promise<{
  stores: ShortStore[]
}> => {
  try {
    const { data } = await fetchBFF(`/stores`, {
      headers,
      method: 'GET',
      params,
      timeout: 25000,
    })
    return data
  } catch (error: any) {
    if (error.code === 'ECONNABORTED' && !!error.config?.timeout) {
      reportError({
        message: 'Request timeout when trying to fetch stores.',
        level: Severity.Critical,
        metadata: { error },
        page: location.pathname,
        source: ErrorSource.WEB_APP,
      })
    } else if (error?.response?.status !== 401) {
      reportError({
        message: error.response?.message ?? error.response?.description ?? error.message,
        level: Severity.Error,
        metadata: { error, response: error.response },
        page: location.pathname,
        source: ErrorSource.WEB_APP,
      })
    }

    return Promise.resolve(handleServiceError(error))
  }
}

export const updateUserStore = async (
  storeId: string,
  data: { businessName?: string | null; phoneNumber?: string | null; email?: string | null },
): Promise<any> => {
  try {
    return await fetchBFF(`/stores/${storeId}`, {
      headers,
      method: 'patch',
      data,
    })
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}

export const getDefaultStore = async ({ id, dutyFreeStores }: GetUserDefaultStoreParams): Promise<any> => {
  try {
    const { data }: { data: { store: AppState['currentStore'] } } = await fetchBFF(`/stores/${id}`, {
      headers,
      method: 'GET',
      timeout: 25000,
      params: {
        fields:
          'id,priceList,businessName,dni,email,street,streetNumber,location,subLocation,phoneNumber,branchOfficeId,storeName,name,categoryName,categoryId,mainCategoryName,mainCategoryId,latitude,longitude,creditAmount,creditBalance,dutyFreePriceList,channelMkt,branchOffice,productGroups,visitWeekdays,plant,marketChain,countrySpecific,deletedAt',
      },
    })

    const promotionSkus: number[] = []
    if (data.store?.promotionSkus && data.store?.promotionSkus.length > 0) {
      const promotionSkusQuery = data.store.promotionSkus.map((sku) => `sku=${sku}`)

      const promotionsProductsAccessible = await getProducts(
        `(${promotionSkusQuery.join(' OR ')}) AND ${createAlgoliaGlobalFilters({
          customerGroups: data.store.productGroups,
          customerPriceList: data.store.priceList,
          customerBranchOfficeId: data.store.branchOfficeId,
          customerChannel: data.store.countrySpecific?.channel,
          customerShowBagInBoxProducts: data.store.showBagInBoxProducts,
        })}`,
        { hitsPerPage: 1000 },
        undefined,
        true,
      )(
        data.store?.branchOfficeId,
        !!dutyFreeStores?.find((store) => `${store}` === data.store?.id),
        data.store.id,
        data.store.priceList,
        data.store?.dutyFreePriceList,
        data.store.countrySpecific?.channel,
      )

      promotionsProductsAccessible.forEach(({ sku }) => promotionSkus.push(sku))
    }
    return { store: { ...data.store, promotionSkus } }
  } catch (error: any) {
    if (error.code === 'ECONNABORTED' && error.message?.includes('timeout')) {
      reportError({
        message: 'Request timeout when trying to fetch stores.',
        level: Severity.Critical,
        metadata: { error },
        page: location.pathname,
        source: ErrorSource.WEB_APP,
      })
    } else if (error?.response?.status !== 401) {
      reportError({
        message: error.response?.message ?? error.response?.description ?? error.message,
        level: Severity.Error,
        metadata: { error, response: error.response },
        page: location.pathname,
        source: ErrorSource.WEB_APP,
      })
    }

    return Promise.resolve(await handleServiceError(error))
  }
}

export const addStoreFavoriteProduct = async ({ id, product }: AddStoreFavoriteProduct): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/stores/${id}/favorite-products`, {
      headers,
      method: 'POST',
      data: { ...product },
    })

    return data
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at adding new favorite product. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }
      reportError({
        message: errorInfo.message,
        level: Severity.Error,
        metadata: { error: errorInfo, response: errorInfo.response },
        source: ErrorSource.WEB_APP,
      })
    }
    return Promise.resolve(handleServiceError(error))
  }
}

export const addStoreMultipleFavoriteProduct = async ({
  id,
  products,
}: AddStoreMultipleFavoriteProduct): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/stores/${id}/multiple-favorite-products`, {
      headers,
      method: 'POST',
      data: { products },
    })

    return data
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at adding new favorite products. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }
      reportError({
        message: errorInfo.message,
        level: Severity.Error,
        metadata: { error: errorInfo, response: errorInfo.response },
        source: ErrorSource.WEB_APP,
      })
    }
    return Promise.resolve(handleServiceError(error))
  }
}

export const deleteStoreFavoriteProduct = async ({ id, productId }: DeleteStoreFavoriteProduct): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/stores/${id}/favorite-products/${productId}`, {
      headers,
      method: 'DELETE',
    })
    return data
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at deleting favorite product. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }
      reportError({
        message: errorInfo.message,
        level: Severity.Error,
        metadata: { error: errorInfo, response: errorInfo.response },
        source: ErrorSource.WEB_APP,
      })
    }
    return Promise.resolve(handleServiceError(error))
  }
}

export const signUp = async (params: SignUpParams): Promise<any> => {
  try {
    return await fetchBFF('/auth/signup', { data: params, headers, method: 'POST' })
  } catch (error: any) {
    const errorInfo = {
      message: `Error ocurred at signup. message="${error?.message}"; response="${error?.response}"`,
      response: error?.response,
    }

    reportError({
      message: errorInfo.message,
      level: Severity.Critical,
      metadata: { error: errorInfo, response: errorInfo.response },
      page: '/signup',
      source: ErrorSource.AUTH_SERVICE,
    })

    console.error('Error at completing signup process.')
    return Promise.resolve(handleServiceError(error))
  }
}

export const accessRecovery = async (params: AccessRecoveryParams): Promise<any> => {
  const { username, successUrl, failureUrl } = params
  try {
    const { data } = await fetchBFF(`/auth/access-recovery`, {
      data: { username, successUrl, failureUrl },
      headers,
      method: 'POST',
    })
    return data
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at  password recovery. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }

      reportError({
        message: errorInfo.message,
        level: Severity.Error,
        metadata: { error: errorInfo, response: errorInfo.response },
        page: '/password-recovery',
        source: ErrorSource.AUTH_SERVICE,
      })

      console.error('Error sending access recovery request.')
    }
    return Promise.resolve(handleServiceError(error))
  }
}

export const changePassword = async ({ userId, password, secretCode }: ChangePasswordParams): Promise<any> => {
  try {
    return await fetchBFF(`/auth/${userId}/access-recovery/${secretCode}`, {
      data: { password },
      headers,
      method: 'POST',
    })
  } catch (error: any) {
    if (error.response?.status >= 500) {
      const errorInfo = {
        message: `Error ocurred at setting a new password at password recovery. message="${error?.message}"; response="${error?.response}"`,
        response: error?.response,
      }

      reportError({
        message: errorInfo.message,
        level: Severity.Error,
        metadata: { error: errorInfo, response: errorInfo.response },
        page: '/password-recovery/new-password',
        source: ErrorSource.AUTH_SERVICE,
      })

      console.error('Cannot set new password.')
    }
    return Promise.resolve(handleServiceError(error))
  }
}

export const validateActiveUser = async (username: string): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/auth/validate-active-user/${username}`, {
      headers,
      method: 'GET',
    })

    return data
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}

export const sendEmailChangeRequest = async (params: SendEmailChangeRequestParams): Promise<any> => {
  try {
    const { userId, newEmail } = params

    return await fetchBFF(`/users/${userId}/change-email-request`, {
      data: { newEmail },
      headers,
      method: 'POST',
    })
  } catch (error: any) {
    if (error?.response?.status === 403) {
      return { emailAlreadyInUse: true }
    }

    return Promise.resolve(handleServiceError(error))
  }
}

export const verifyEmailChangeRequestCode = async (params: VerifyEmailChangeRequestCodeParams): Promise<any> => {
  try {
    const { code, userId } = params

    const { data } = await fetchBFF(`/users/${userId}/change-email-request/verify-code`, {
      data: { code },
      headers,
      method: 'POST',
    })

    return data
  } catch (error: any) {
    if (error?.response?.status === 403) {
      return { invalidCode: true }
    }

    return Promise.resolve(handleServiceError(error))
  }
}
export const sendPasswordChangeRequest = async (params: SendPasswordChangeRequestParams): Promise<any> => {
  try {
    const { userId, newPassword } = params

    return await fetchBFF(`/users/${userId}/change-password-request`, {
      data: { newPassword },
      headers,
      method: 'POST',
    })
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}

export const verifyPasswordChangeRequestCode = async (params: VerifyPasswordChangeRequestCodeParams): Promise<any> => {
  try {
    const { code, userId } = params

    const { data } = await fetchBFF(`/users/${userId}/change-password-request/verify-code`, {
      data: { code },
      headers,
      method: 'POST',
    })

    return data
  } catch (error: any) {
    if (error?.response?.status === 403) {
      return { invalidCode: true }
    }

    return Promise.resolve(handleServiceError(error))
  }
}

export const getNotActivatedUsers = async (storeId: number): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/users/users-to-activate/${storeId}`, {
      headers,
      method: 'GET',
      params: {
        fields:
          'id,priceList,businessName,dni,email,street,streetNumber,location,subLocation,phoneNumber,branchOfficeId,storeName,name,categoryName,categoryId,mainCategoryName,mainCategoryId,latitude,longitude,creditAmount,creditBalance,dutyFreePriceList,channelMkt,branchOffice,productGroups,visitWeekdays,plant,marketChain,countrySpecific,deletedAt',
      },
    })

    return data
  } catch (error: any) {
    if (error?.response?.status === 400) {
      return { alreadyActivated: true }
    }
    if (error?.response?.status === 404) {
      return { storeNotFound: true }
    }
    if (error?.response?.status === 409) {
      return { noUsersForStore: true }
    }

    return Promise.resolve(handleServiceError(error))
  }
}

export const requestActivationMail = async (userId: string): Promise<any> => {
  try {
    const { data } = await fetchBFF(`/users/send-activation-mail/${userId}`, {
      headers,
      method: 'POST',
    })

    return data
  } catch (error: any) {
    return Promise.resolve(handleServiceError(error))
  }
}
