import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import usePortal from '../../hooks/usePortal'
import { ZIndex } from '../../types'
import { isBrowser } from '../../utils/ssr'

interface Props {
  children?: ReactNode
  zIndex: ZIndex
  isActive: boolean
  hideScrollbars?: boolean
  onOutsideClick?: any
  className?: string
  isFixed?: boolean
  backgroundOpacity?: 'normal' | 'dark'
}

const Overlay = ({
  children,
  zIndex,
  isActive = true,
  onOutsideClick,
  className = '',
  hideScrollbars = false,
  isFixed,
  backgroundOpacity = 'normal',
}: Props) => {
  if (!isBrowser || !isActive) return null

  const element = usePortal({ id: 'overlay' }) || document.body

  const wrapperClassName = `w-screen h-full ${isFixed ? 'fixed' : 'absolute'} z-${zIndex} top-0  bg-black ${
    backgroundOpacity === 'dark' ? 'bg-opacity-70' : 'bg-opacity-40'
  } ${hideScrollbars ? 'overflow-hidden' : 'overflow-x-hidden overflow-y-auto'} ${className}`

  function handleClickOutside(e: any) {
    if (onOutsideClick && e.currentTarget === e.target) {
      onOutsideClick()
    }
  }

  const ParentElement = (
    <RemoveScroll forwardProps>
      <div className={wrapperClassName} onClick={handleClickOutside}>
        {children}
      </div>
    </RemoveScroll>
  )

  return createPortal(ParentElement, element)
}

export default Overlay
