import { signIn, signInByKey, SignInByKeyParams, SignInParams, signOutRedirect } from '../../services/auth'
import { Onboarding, signUp, SignUpParams, updateUserOnboarding } from '../../services/user'
import { saveState } from '../../utils/localstorage'
import { authKeys } from '../../utils/store'
import { AuthDispatch, AuthState } from './context'

export function persistCredentials(params: {
  accessToken: string
  refreshToken?: string
  accessTokenExpiration?: number
  refreshTokenExpiration?: number
}) {
  if (params.refreshTokenExpiration) saveState(authKeys.refreshTokenExpiration, params.refreshTokenExpiration * 1000)
  if (params.accessTokenExpiration) saveState(authKeys.accessTokenExpiration, params.accessTokenExpiration * 1000)
  if (params.accessToken) saveState(authKeys.accessToken, params.accessToken)
  if (params.refreshToken) saveState(authKeys.refreshToken, params.refreshToken)
}

export function actionReset(dispatch: AuthDispatch) {
  return () => dispatch({ type: 'RESET' })
}

export function actionSignIn(dispatch: AuthDispatch) {
  return async (params: SignInParams) => {
    try {
      dispatch({ type: 'SIGN_IN_BEGINNING' })

      const { accessToken, refreshToken, accessTokenExpiration, refreshTokenExpiration, user } = await signIn({
        username: params.username,
        password: params.password,
      })

      persistCredentials({
        accessToken,
        refreshToken,
        accessTokenExpiration,
        refreshTokenExpiration,
      })

      dispatch({
        type: 'SET_USER',
        dni: user.dni,
        name: user.name,
        defaultStoreId: user.metadata.defaultStore ?? '',
        email: user.email,
        phone: user.phone,
        id: user.id,
        canEditStoreData: user.metadata.canEditStoreData,
        canEditUserData: user.metadata.canEditUserData,
        canViewCredit: user.metadata.canViewCredit,
        test: user.metadata.test,
        onboarding: user.metadata.onboarding ?? '',
        visibleStores: user.metadata.visibleStores ?? [],
        dutyFreeStores: user.metadata.dutyFreeStores ?? [],
        referenceType: user.referenceType,
      })

      dispatch({ type: 'SIGN_IN', needPasswordUpdate: user.needPasswordUpdate })

      return { needPasswordUpdate: user.needPasswordUpdate, visibleStores: user.metadata.visibleStores ?? [] }
    } finally {
      dispatch({ type: 'SIGN_IN_FINISHED' })
    }
  }
}
export function actionSignInByKey(dispatch: AuthDispatch) {
  return async ({ key, requestId, origin }: SignInByKeyParams) => {
    try {
      dispatch({ type: 'SIGN_IN_BEGINNING' })

      const { accessToken, refreshToken, accessTokenExpiration, refreshTokenExpiration, user } = await signInByKey({
        key,
      })

      persistCredentials({
        accessToken,
        refreshToken,
        accessTokenExpiration,
        refreshTokenExpiration,
      })

      dispatch({
        type: 'SET_USER',
        dni: user.dni,
        name: user.name,
        defaultStoreId: user.metadata.defaultStore ?? '',
        email: user.email,
        phone: user.phone,
        id: user.id,
        canEditStoreData: user.metadata.canEditStoreData,
        canEditUserData: user.metadata.canEditUserData,
        canViewCredit: user.metadata.canViewCredit,
        test: user.metadata.test,
        onboarding: user.metadata.onboarding ?? '',
        visibleStores: user.metadata.visibleStores ?? [],
        dutyFreeStores: user.metadata.dutyFreeStores ?? [],
        inviteeRequestId: requestId,
        origin,
        referenceType: user.referenceType,
      })

      dispatch({ type: 'SIGN_IN', needPasswordUpdate: user.needPasswordUpdate })

      return { needPasswordUpdate: user.needPasswordUpdate }
    } finally {
      dispatch({ type: 'SIGN_IN_FINISHED' })
    }
  }
}

export function actionUpdateOnboarding(dispatch: AuthDispatch) {
  return async ({ userId, onboarding }: { userId: string; onboarding: Onboarding }) => {
    try {
      const { message } = await updateUserOnboarding({
        userId,
        onboarding,
      })

      dispatch({
        type: 'UPDATE_ONBOARDING',
        onboarding,
      })

      return { message }
    } finally {
      dispatch({ type: 'SIGN_IN_FINISHED' })
    }
  }
}

export function actionSignUp(dispatch: AuthDispatch) {
  return async (params: SignUpParams) => {
    await signUp({ ...params })
    dispatch({ type: 'SIGN_UP', newUserData: { ...params } })
  }
}

export function actionChangeDefaultStoreId(dispatch: AuthDispatch) {
  return (newDefaultStoreId: AuthState['defaultStoreId']) =>
    dispatch({ type: 'CHANGE_DEFAULT_STORE_ID', newDefaultStoreId })
}

export function actionChangeAuthState(dispatch: AuthDispatch) {
  return (newAuthState: Partial<AuthState>) => dispatch({ type: 'CHANGE_AUTH_STATE', newAuthState })
}

export function actionSignOut() {
  return () => {
    signOutRedirect()
  }
}
