import { captureMessage, Event, EventHint, init, setUser, SeverityLevel } from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import { APP_VERSION, SENTRY_DSN, STAGE } from '../config'
import { loadState } from './localstorage'
import { isValidJSONString } from './object'
import { authKeys } from './store'

export enum ErrorSource {
  ORDERS_SERVICE = 'orders-service',
  AUTH_SERVICE = 'auth-service',
  WEB_APP = 'webapp',
  PAYMENTS_PLQ = 'payments-plq',
}

type Hint = EventHint & {
  originalException?: Error | any
}

const Severity = {
  Critical: 'fatal' as SeverityLevel,
  Error: 'error' as SeverityLevel,
  Info: 'info' as SeverityLevel,
  Warning: 'warning' as SeverityLevel,
  Debug: 'debug' as SeverityLevel,
  Log: 'log' as SeverityLevel,
}

type CaptureErrorParams = {
  message: string
  level?: SeverityLevel
  page?: string
  source?: ErrorSource
  metadata?: Record<string, unknown>
  tags?: Record<string, string | number>
}

// Only send the errors sent via reportError()
const filterErrors = (e: Event, hint: Hint): Event | null | PromiseLike<Event | null> => {
  if (e?.tags?.source) {
    if (!e.tags?.source || !e.message) {
      e.level = 'info'
    }

    if (hint?.originalException?.response?.data?.errors) {
      e.message = hint.originalException.response.data.errors[0]?.detail

      if (e.exception) e.exception.values = [{ value: e.message }]
    }

    if (hint.originalException?.config?.data) {
      e.contexts = { ...e.contexts, metadata: { request_data: JSON.parse(hint.originalException.config.data) } }
    }

    if (hint?.originalException?.config?.data && isValidJSONString(hint?.originalException.config.data)) {
      e.contexts = {
        ...e.contexts,
        metadata: {
          request_payload: JSON.parse(hint?.originalException?.config?.data ?? '{}'),
        },
      }
    }

    return e
  }

  return null
}

const beforeNavigate = (context) => {
  const name = location.pathname.replace(/\/\d+/g, '/:id')
  return { ...context, name }
}

export const initSentry = () => {
  return init({
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment: STAGE,
    integrations: [new BrowserTracing({ beforeNavigate })],
    tracesSampleRate: 0.2,
    beforeSend: filterErrors,
    normalizeDepth: 10,
    enabled: STAGE !== 'dev',
    // ignoreErrors: ['Network Error'], // string[] : Error messages to ignore,
  })
}

export const setSentryUserIdentifier = ({ email, id }: { email: string; id: string }) => {
  setUser({
    email,
    username: email,
    id,
  })
}

export const reportError = ({
  message,
  level = Severity.Info,
  page,
  source = ErrorSource.WEB_APP,
  tags = {},
  metadata = {},
}: CaptureErrorParams) => {
  const appStore: any = loadState(authKeys.app)

  return captureMessage(message, {
    level,
    contexts: {
      metadata: { page, message, source, ...metadata },
      appContext: {
        accessToken: loadState(authKeys.accessToken),
        refreshToken: loadState(authKeys.refreshToken),
        auth: loadState(authKeys.auth),
        stores: appStore?.stores,
        currentStore: appStore?.currentStore,
      },
    },
    tags: { source, ...tags },
  })
}

export { Severity }
