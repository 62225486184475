/* eslint:disable:no-empty */
import 'react-toastify/dist/ReactToastify.css'
import './toast-styles.css'

import React, { createContext, MutableRefObject, ReactNode, useContext, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import { IconProps, ToastCard } from '../../ui'
import { ToastSizes } from '../../ui/molecules/ToastCard'

export enum ToastTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  FAVORITE = 'favorite',
}

export interface ShowToastProps {
  message: React.ReactNode
  type: ToastTypes
  size?: ToastSizes
  icon?: IconProps['id']
  iconBg?: string
  countdownTimer?: number
}

export const ToastContextInitialState = {
  // eslint-disable-next-line
  showToast: (_: ShowToastProps) => {},
}

export const ToastContext = createContext(ToastContextInitialState)

export const getIconPropsByToastType = (
  type: ShowToastProps['type'],
): { iconId: IconProps['id']; iconBgColor: string } => {
  if (type === 'success') return { iconId: 'success', iconBgColor: 'bg-success' }
  if (type === 'error') return { iconId: 'error_outline', iconBgColor: 'bg-error' }
  if (type === 'warning') return { iconId: 'warning_outline', iconBgColor: 'bg-warning' }
  if (type === 'favorite') return { iconId: 'heart_filled', iconBgColor: 'bg-[#FEE5E6]' }
  return { iconId: 'info_outline', iconBgColor: 'bg-dark' }
}

interface AddTimerToMessageProps {
  timer: number
  message: ReactNode
}
const addTimerToMessage = (props: AddTimerToMessageProps) => {
  const { message, timer } = props
  return message?.toString().replace('<#countdown>', `${timer}`)
}

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const toastId: MutableRefObject<string | number> = useRef('')

  const showToast = ({ message, type, size, icon, iconBg, countdownTimer }: ShowToastProps) => {
    const { iconId, iconBgColor } = getIconPropsByToastType(type)

    const toastMessage = countdownTimer ? addTimerToMessage({ message, timer: countdownTimer }) : message

    toastId.current = toast(
      <ToastCard
        message={toastMessage}
        iconId={icon ?? iconId}
        iconBgColor={iconBg ?? iconBgColor}
        size={size}
        type={type}
      />,
      {
        position: size === ToastSizes.SMALL ? 'top-right' : 'top-center',
        type: 'success',
        className: 'p-0 shadow-none',
      },
    )

    if (countdownTimer) {
      let timer = countdownTimer
      const interval = setInterval(() => {
        if (timer > 1) {
          timer -= 1
          toast.update(toastId.current, {
            render: () => (
              <ToastCard
                message={addTimerToMessage({ message, timer })}
                iconId={icon ?? iconId}
                iconBgColor={iconBg ?? iconBgColor}
                size={size}
                type={type}
              />
            ),
          })
        }

        return () => clearInterval(interval)
      }, 1000)
    }
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer
        icon={false}
        closeOnClick={true}
        hideProgressBar={true}
        closeButton={false}
        limit={1}
        draggable={false}
        autoClose={2000}
      />
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const { showToast } = useContext(ToastContext)

  return { showToast }
}
