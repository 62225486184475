import React from 'react'

import { isChile } from '../../../i18n'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Alert from '../../molecules/Alert'

interface ModalDeletedProps {
  isOpen: boolean
  onClose: () => void
  deletedStore: {
    label: string
    value: string | number
    deleted: boolean
  }
}

const ModalDeleted = ({ isOpen, onClose, deletedStore }: ModalDeletedProps) => {
  return (
    <Alert
      isActive={isOpen}
      onClose={onClose}
      className="max-w-86 tablet:max-w-108 w-full"
      bodyClassName="flex flex-col"
      useDefaultStyles={false}
      useDefaultBodyClasses={false}
    >
      <div className="flex items-center mb-6">
        <Icon id="red_alert" size={40} className="mr-4" />
        <h6 className="text-black-high text-2xl font-black">Local inactivo</h6>
      </div>
      <p className="text-black-high text-md leading-5 mb-10">
        {`Tu local `}
        <span className="font-black">{deletedStore.label}</span>
        {` con `}
        <span className="font-black">ID {deletedStore.value}</span>
        {` se encuentra temporalmente inactivo, para obtener más información comunícate con
        el centro de ayuda al `}
        <a className="font-black" href={`tel:${isChile ? '+56800721212' : '+591800102653'}`}>
          {isChile ? '800 72 12 12' : '800 10 2653'}
        </a>
      </p>
      <Button type="primary" className="w-full tablet:w-57 h-10 self-center" onClick={onClose} label="Entendido" />
    </Alert>
  )
}

export default ModalDeleted
