import React from 'react'

import { Icon } from '../../ui'

interface Props {
  title?: string | React.ReactNode
  description?: string
  showIcon?: boolean
}

const Announcement = ({ title, description, showIcon = true }: Props) => {
  return (
    <div className="mt-4.5">
      <div className="flex items-center">
        {showIcon && <Icon className="mr-1.5" id="time_clock" color="#1890FF" size={16} />}
        <div>
          <p className="text-blue text-xs-new">{title}</p>
        </div>
      </div>
      <p className="text-xs-new px-6">{description}</p>
    </div>
  )
}

export default Announcement
