import { Link } from 'gatsby'
import * as React from 'react'

import { useByCountry } from '../../i18n'
import COCA_COLA_LOGO_WHITE from '../../images/cocacola-white.png'
import { Icon } from '../../ui'
import { EventCategory, sendGtagEvent } from '../../utils/analytics'
import { capitalizeFirstLetter } from '../../utils/strings'

interface FooterLinks {
  hRef: string
  text: string
  openNewTab: boolean
}

interface FooterLogo {
  file: {
    url: string
  }
}

export interface FooterProps {
  demo?: string
  className?: string
  primaryLinks?: FooterLinks[]
  secondaryLinks?: FooterLinks[]
  socialNetworkLinks?: FooterLinks[]
  logo?: FooterLogo
  copyright?: string
}

const Footer = (props: FooterProps) => {
  return (
    <footer className={`${props.className} w-full bg-primary`}>
      <div className="w-full container desktop:container h-40  desktop:py-4 desktop:h-44 grid grid-cols-4 grid-rows-footer tablet:grid-rows-footer-lg justify-center items-center ">
        <div className="flex flex-row col-start-1 col-end-5 w-full tablet:col-start-2 tablet:col-end-4 tablet:ml-4 desktop:mt-4 justify-between">
          {props.primaryLinks ? (
            props.primaryLinks.map((link, i) => {
              if (link.openNewTab) {
                return (
                  <a
                    href={link.hRef}
                    className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer"
                    target={link.openNewTab ? '_blank' : undefined}
                    rel="noreferrer"
                    key={i}
                    onClick={() => {
                      sendGtagEvent({
                        eventCategory: EventCategory.Layout,
                        eventAction: `Ir a ${link.text}`,
                        eventLabel: 'Footer',
                      })
                    }}
                  >
                    {link.text}
                  </a>
                )
              }
              return (
                <Link
                  to={link.hRef}
                  className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer"
                  key={i}
                  onClick={() => {
                    sendGtagEvent({
                      eventCategory: EventCategory.Layout,
                      eventAction: `Ir a ${link.text}`,
                      eventLabel: 'Footer',
                    })
                  }}
                >
                  {link.text}
                </Link>
              )
            })
          ) : (
            <>
              <span className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer">
                Embonor
              </span>
              <span className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer">
                Covid-19
              </span>
              <span className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer">
                Bases Legales
              </span>
              <span className="text-semi desktop:text-sm desktop:text-desktop font-bold text-white cursor-pointer">
                Ayuda
              </span>
            </>
          )}
        </div>
        <div
          className={`w-38 ${useByCountry(
            'desktop:w-56',
            'desktop:w-46',
          )} pb-2 col-start-1 col-end-2 tablet:row-start-1 tablet:row-end-1`}
        >
          <img src={props.logo?.file?.url || COCA_COLA_LOGO_WHITE} alt="logo" />
        </div>
        <div className="w-full col-start-4 col-end-5 flex justify-end pl-5 gap-4">
          {props.socialNetworkLinks &&
            props.socialNetworkLinks.map((link, i) => {
              if (link.openNewTab) {
                return (
                  <a
                    key={i}
                    href={link.hRef}
                    target={link.openNewTab ? '_blank' : undefined}
                    rel="noreferrer"
                    onClick={() => {
                      sendGtagEvent({
                        eventCategory: EventCategory.Layout,
                        eventAction: `Ir a ${capitalizeFirstLetter(link?.text)}`,
                        eventLabel: 'Footer',
                      })
                    }}
                  >
                    <Icon id={link.text as any} size={26} color="white" className="cursor-pointer ml-2" />
                  </a>
                )
              }

              return (
                <Link
                  key={i}
                  to={link.hRef}
                  onClick={() => {
                    sendGtagEvent({
                      eventCategory: EventCategory.Layout,
                      eventAction: `Ir a ${capitalizeFirstLetter(link?.text)}`,
                      eventLabel: 'Footer',
                    })
                  }}
                >
                  <Icon id={link.text as any} size={26} color="white" className="cursor-pointer ml-2" />
                </Link>
              )
            })}
        </div>
        <div className="w-full px-4 col-start-1 col-end-5 flex flex-col items-center desktop:flex-row desktop:justify-center">
          <p className="text-tiny text-center desktop:text-right desktop:pr-4 desktop:text-sm text-white desktop:flex-shrink-0">
            {props.copyright || '© Coca-Cola Embonor 2021. Todos los Derechos Reservados.'}
          </p>
          <div className=" text-tiny desktop:text-sm text-white min-h- flex  flex-wrap justify-center desktop:justify-start">
            {props.secondaryLinks ? (
              <>
                {props.secondaryLinks.map((link, i) => (
                  <React.Fragment key={`${i}-line`}>
                    {i === 0 ? (
                      ''
                    ) : (
                      <span key={`${i}-line`} className={`mx-1 ${i > 1 ? 'hidden desktop:block' : ''}`}>
                        |
                      </span>
                    )}
                    {link.openNewTab ? (
                      <a
                        target={link.openNewTab ? '_blank' : undefined}
                        href={link.hRef}
                        className={`font-bold text-white cursor-pointer ${i > 1 ? 'hidden desktop:block' : ''}`}
                        rel="noreferrer"
                        onClick={() => {
                          sendGtagEvent({
                            eventCategory: EventCategory.Layout,
                            eventAction: `Ir a ${link.text}`,
                            eventLabel: 'Footer',
                          })
                        }}
                      >
                        {link.text}
                      </a>
                    ) : (
                      <Link
                        to={link.hRef}
                        className={`font-bold text-white cursor-pointer ${i > 1 ? 'hidden desktop:block' : ''}`}
                        onClick={() => {
                          sendGtagEvent({
                            eventCategory: EventCategory.Layout,
                            eventAction: `Ir a ${link.text}`,
                            eventLabel: 'Footer',
                          })
                        }}
                      >
                        {link.text}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <span className="font-bold text-white cursor-pointer">Términos y condiciones</span>
                <span className="mx-1">|</span>
                <span className="font-bold text-white cursor-pointer">Políticas de privacidad</span>
                <span className="mx-1 hidden desktop:block">|</span>
                <span className="font-bold text-white cursor-pointer hidden desktop:block">Bases legales</span>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
