import { loadState, removeState } from './localstorage'

export const authKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  refreshTokenExpiration: 'refreshTokenExpiration',
  accessTokenExpiration: 'accessTokenExpiration',
  auth: 'auth-v1',
  app: 'app',
}

export const cartKeys = {
  cart: 'cart-v2',
}

export const getAccessToken = () => loadState<string>(authKeys.accessToken)
export const getRefreshToken = () => loadState<string>(authKeys.refreshToken)
export const getRefreshTokenExpiration = () => loadState<number>(authKeys.refreshTokenExpiration)
export const getAccessTokenExpiration = () => loadState<number>(authKeys.accessTokenExpiration)
export const clearRefreshToken = () => removeState({ key: authKeys.refreshToken })
export const authStoreClear = () => {
  const signoutKeys = { ...authKeys }
  Object.values(signoutKeys).forEach((key) => removeState({ key }))
  Object.values(cartKeys).forEach((key) => removeState({ key }))
}
