export const getFalsyEntries = (object: Record<string, any>) => Object.entries(object).filter(([__, value]) => !value)

export const isValidJSONString = (jsonString: string) => {
  try {
    JSON.parse(jsonString)
    return true
  } catch (err) {
    return false
  }
}
