import React from 'react'

import { Tab } from '../index'

interface TabsGroupProps {
  tabs: any
  className: string
}

const TabsGroup = (props: TabsGroupProps) => {
  return (
    <div className={props.className}>
      {props.tabs?.map((tab) => (
        <Tab label={tab.label} onClick={tab.onClick} icon={tab.icon} isActive={tab.isActive} key={tab.label} />
      ))}
    </div>
  )
}

export default TabsGroup
