import React, { ReactNode, useEffect, useReducer } from 'react'

import { loadState, saveState } from '../../utils/localstorage'
import { setSentryUserIdentifier } from '../../utils/sentry'
import { authKeys } from '../../utils/store'
import {
  actionChangeAuthState,
  actionChangeDefaultStoreId,
  actionReset,
  actionSignIn,
  actionSignInByKey,
  actionSignOut,
  actionSignUp,
  actionUpdateOnboarding,
} from './actions'
import { AuthContext, AuthState, DispatchContext } from './context'
import reducer from './reducer'

/*
 * FIXME:
 *  - refreshToken is a boolean. Should have a prefix
 *  - persistedState should have the type of the state
 * */

export function AuthProvider(props: { children: ReactNode }) {
  const { children } = props

  const refreshToken = !!loadState(authKeys.refreshToken)
  const persistedState = loadState<AuthState>(authKeys.auth)

  let initialState = persistedState ? { ...persistedState, isAuth: refreshToken, error: null } : AuthState

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(location.search)

    if (location.pathname === '/signin' && params.get('key') && params.get('section')) {
      initialState = AuthState
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.email && state.id) {
      setSentryUserIdentifier({
        email: state.email,
        id: state.id,
      })
    }

    saveState(authKeys.auth, state)
  }, [state])

  return (
    <AuthContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const state = React.useContext(AuthContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return {
    state,
    reset: actionReset(dispatch),
    signIn: actionSignIn(dispatch),
    signInByKey: actionSignInByKey(dispatch),
    signUp: actionSignUp(dispatch),
    signOut: actionSignOut(),
    changeDefaultStoreId: actionChangeDefaultStoreId(dispatch),
    updateOnboarding: actionUpdateOnboarding(dispatch),
    changeAuthState: actionChangeAuthState(dispatch),
  }
}
