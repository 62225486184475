import axios, { AxiosInstance, AxiosResponse } from 'axios'

import * as config from '../../config'
import { insertAuthorizationHeader, renewTokens } from './interceptors'

export const fetch: AxiosInstance = axios.create({ baseURL: config.EMBONOR_AUTHENTICATION_SERVICE })

export const getNewAccessToken = async (refreshToken?: string): Promise<AxiosResponse<{ token: string }>> => {
  return axios({
    method: 'GET',
    baseURL: config.EMBONOR_AUTHENTICATION_SERVICE,
    url: '/jwt/refresh',
    headers: { Authorization: `Bearer ${refreshToken}` },
  })
}

export const retryRequest = (config: { headers: { Authorization: string } }, accessToken: string) => {
  return Promise.resolve(axios({ ...config, headers: { ...config.headers, Authorization: `Bearer ${accessToken}` } }))
}

fetch.interceptors.request.use(insertAuthorizationHeader, (error) => Promise.reject(error))
fetch.interceptors.response.use((response) => response, renewTokens())
