import React, { ReactNode, useRef } from 'react'

import { useAppStore } from '../../contexts'
import { useByCountry } from '../../i18n'
import Icon from '../atoms/Icon'
import Overlay from '../molecules/Overlay'

interface SideBarProps {
  onClickLogo: () => void
  onClose: () => void
  isOpen: boolean
  children: ReactNode
}

const SideBar = (props: SideBarProps) => {
  const { onClickLogo, onClose, isOpen, children } = props

  const navRef = useRef<HTMLElement>(null)

  const {
    state: { companyLogoColorfulAlt },
  } = useAppStore()

  return (
    <Overlay zIndex={50} isActive={isOpen} onOutsideClick={onClose} isFixed>
      <nav
        ref={navRef}
        className="w-80 h-screen bg-white fixed top-0 flex justify-center lg:hidden z-60 overflow-auto"
        style={{ transition: isOpen ? '350ms' : '850ms', left: isOpen ? '0' : '-100%' }}
        tabIndex={1}
      >
        <div className="w-full">
          <div className="w-full h-16 flex items-center justify-between px-4">
            <img
              src={companyLogoColorfulAlt}
              alt="logo"
              className={`cursor-pointer ${useByCountry('w-38', 'w-34')}`}
              onClick={onClickLogo}
            />
            <Icon id="close" color="gray" onClick={onClose} size={28} className="cursor-pointer" />
          </div>
          {children}
        </div>
      </nav>
    </Overlay>
  )
}

export default SideBar
