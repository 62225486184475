import React, { ReactNode, useState } from 'react'

import { LoadingContext } from './context'

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [isGlobalLoading, setIsGlobalLoading] = useState(false)

  return <LoadingContext.Provider value={{ isGlobalLoading, setIsGlobalLoading }}>{children}</LoadingContext.Provider>
}

export const useLoading = () => {
  const { isGlobalLoading, setIsGlobalLoading } = React.useContext(LoadingContext)

  if (isGlobalLoading === undefined || setIsGlobalLoading === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }

  return {
    isGlobalLoading,
    setIsGlobalLoading,
  }
}
