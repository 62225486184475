import React from 'react'

interface ProgressBarProps {
  progress: number
  className?: string
  bgColor?: string
  progressColor?: string
}

const ProgressBar = ({
  progress,
  className = '',
  bgColor = 'bg-gray-300',
  progressColor = 'bg-success',
}: ProgressBarProps) => {
  return (
    <div className={`w-full h-2 rounded-full ${bgColor} ${className}`}>
      <div
        className={`h-full rounded-full transition-all ${progressColor}`}
        style={{ width: `${progress > 100 ? 100 : progress}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
