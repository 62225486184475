import './styles.css'

import React, { useRef, useState } from 'react'

import { GtagEvent, sendGtagEvent } from '../../../utils/analytics'
import { isMobile } from '../../../utils/viewport'
import Icon from '../Icon'
import Image from '../Image'

type Props = React.HTMLAttributes<HTMLVideoElement> & {
  src: string
  thumbnail?: string
  controls?: boolean
  preload?: 'none' | 'auto' | 'metadata'
  title?: string
  description?: string
  gtmData?: GtagEvent
}
const VideoPlayer = ({
  thumbnail,
  className = '',
  controls = true,
  title,
  description,
  preload,
  src,
  gtmData,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isStarted, setIsStarted] = useState(false)

  return (
    <div className={`w-full ${className} overflow-hidden relative cursor-pointer`}>
      <video
        ref={videoRef}
        className="block focus:outline-none active:outline-none shadow-lg min-w-full w-full relative"
        controls={controls}
        src={src}
        onEnded={() => {
          setIsStarted(false)
        }}
        preload={preload}
      />

      {!isStarted && (
        <div
          className={` h-full w-full absolute overflow-hidden top-0 left-0`}
          onClick={() => {
            setIsStarted(true)
            videoRef.current?.play()
            if (gtmData) sendGtagEvent(gtmData)
          }}
        >
          {thumbnail && (
            <div className="block h-full w-full absolute overflow-hidden top-0 left-0 bg-black z-10">
              <Image
                src={thumbnail}
                preload
                alt="video thumbnail"
                className="w-full h-full object-cover absolute left-0 top-0"
              />
              <div className="w-full h-full demo1" />
              <div className="absolute bottom-0 left-0 p-3 tablet:p-6">
                {title && <p className="text-white font-bold text-sm tablet:text-2xl desktop:text-3xl">{title}</p>}
                {description && (
                  <p className="text-white font-bold text-sm tablet:text-lg desktop:text-xl hidden tablet:block">
                    {description}
                  </p>
                )}
              </div>
            </div>
          )}

          <button
            type="button"
            className="absolute z-10 cursor-pointer left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 focus:outline-none active:outline-none"
          >
            <Icon id="play_button" color="#fff" size={isMobile() ? 40 : 80} strokeWidth={4} />
          </button>
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
