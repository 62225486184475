import React, { InputHTMLAttributes, MouseEvent, Ref, useState } from 'react'

import FieldFeedback from './FieldFeedback'
import Icon, { IconProps } from './Icon'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean
  type?: string
  placeholder?: string
  label?: string
  labelClassName?: string
  message?: string
  error?: boolean
  success?: boolean
  name?: string
  iconPosition?: 'left' | 'right'
  ref?: any
  icon?: IconProps['id']
  iconColor?: string
  className?: string
  showPasswordIcon?: boolean
  textButton?: string
  errorIcon?: IconProps['id']
  successIcon?: IconProps['id']
}

const TextField = React.forwardRef((props: TextFieldProps, ref: Ref<HTMLInputElement>) => {
  const {
    placeholder,
    type,
    label,
    message,
    error,
    success,
    name,
    icon,
    iconColor,
    className,
    disabled,
    iconPosition,
    onClick,
    style,
    labelClassName,
    showPasswordIcon = true,
    errorIcon,
    prefix,
    successIcon,
    ...inputProps
  } = props
  const [isFocus, setIsFocus] = useState(false)
  const formattedType = type === 'password' ? 'text' : type
  const [showText, setShowText] = useState(type !== 'password')

  function handleToggle(e: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault()
    setShowText(!showText)
  }

  const inputStyle: Record<string, any> = icon
    ? { paddingLeft: 45, ...style }
    : type === 'tel' && prefix
      ? { paddingLeft: 54, ...style }
      : { ...style }

  if (icon && iconPosition === 'right') {
    delete inputStyle.paddingLeft
    inputStyle.paddingRight = 45
  }

  return (
    <div className="min-w-full mb-5">
      {label && <label className={labelClassName ?? 'left text-sm'}>{label}</label>}
      <div className="relative">
        {type === 'tel' && (
          <span
            className={`absolute left-2 ${disabled ? 'top-[11px] text-dark' : 'top-[12px] text-black'} ${
              !isFocus ? 'text-dark' : ''
            }`}
          >
            {prefix}
          </span>
        )}
        <input
          {...inputProps}
          name={name}
          disabled={!!disabled}
          ref={ref}
          type={showText ? formattedType : type}
          placeholder={isFocus ? '' : placeholder}
          className={`base-input text-base ${error ? 'error' : ''} ${success && !error ? 'success' : ''} ${
            className ?? ''
          }`}
          style={inputStyle}
          onClick={(e) => onClick?.(e)}
          onFocus={(e) => {
            setIsFocus(true)
            inputProps.onFocus?.(e)
          }}
          onBlur={(e) => {
            setIsFocus(false)
            inputProps.onBlur?.(e)
          }}
        />
        {success && successIcon && (
          <Icon
            id={successIcon}
            size="24"
            className={`absolute right-0 top-0 mr-2 input-right-icon cursor-pointer text-success`}
          />
        )}
        {icon && (
          <Icon
            id={icon}
            size="30"
            className={`absolute text-dark ${
              iconPosition === 'right' ? 'right-0 top-0 mr-2 input-right-icon' : 'left-0 top-2 ml-2 input-left-icon'
            }  cursor-pointer ${iconColor ?? ''}`}
          />
        )}
        {type === 'password' && showPasswordIcon && !disabled && (
          <Icon
            id={showText ? 'eye_open' : 'eye_close'}
            size="30"
            className="absolute text-dark right-0 top-0 mr-4 input-right-icon cursor-pointer"
            onClick={handleToggle}
          />
        )}
      </div>
      {message ? <FieldFeedback icon={errorIcon} error={error} message={message} /> : null}
    </div>
  )
})

export default TextField
