/* eslint-disable @typescript-eslint/member-delimiter-style */
import { isPhoneNumber } from 'class-validator'

import { COUNTRY } from '../config'
import { toTwoDecimals } from '../utils/format'

export const currentCountry = COUNTRY || 'cl'

export interface I18n {
  currencySymbol: string
  userDniName: string
  businessDniName: string
  companyLogoWhite: string
  companyURL: string
  companyLogoColor: string
  phoneCode: string
  numberFormatter: (number: number, isCurrency?: boolean) => string
}

export const CL_I18N: I18n = {
  currencySymbol: '$',
  userDniName: 'RUT',
  businessDniName: 'RUT',
  companyLogoWhite: '/img/logos/embonor-white.svg',
  companyLogoColor: '/img/logos/embonor-color.svg',
  companyURL: 'https://www.embonor.cl',
  phoneCode: '56',
  numberFormatter: (number, _) => {
    const parsedNumber = Math.ceil(number)
    const [integers, decimals] = parsedNumber.toString().split('.', 2)
    const newNumber: string[] = []

    integers
      .split('')
      .reverse()
      .forEach((n, i) => {
        if (i > 0 && i % 3 === 0 && n !== '-') newNumber.push('.')
        newNumber.push(n)
      })

    return `${newNumber.reverse().join('')}${decimals ? `,${decimals}` : ''}`
  },
}

export const BO_I18N: I18n = {
  currencySymbol: 'Bs.',
  userDniName: 'CI',
  businessDniName: 'NIT',
  companyLogoWhite: '/img/logos/embol-white.svg',
  companyLogoColor: '/img/logos/embol-color.svg',
  companyURL: 'https://embol.com',
  phoneCode: '591',
  numberFormatter: (number, isCurrency = false) => {
    const parsedNumber = toTwoDecimals(number)
    const [integers, decimals] = parsedNumber.toString().split('.', 2)
    const newNumber: string[] = []

    integers
      .split('')
      .reverse()
      .forEach((n, i) => {
        if (i > 0 && i % 3 === 0 && n !== '-') newNumber.push(',')
        newNumber.push(n)
      })

    if (isCurrency) {
      return `${newNumber.reverse().join('')}.${decimals ? `${decimals}${decimals.length < 2 ? '0' : ''}` : '00'}`
    }

    return `${newNumber.reverse().join('')}${decimals ? `.${decimals}` : ''}`
  },
}

export const isBolivia = currentCountry === 'bo'

export const isChile = currentCountry === 'cl'

export const i18n = isBolivia ? BO_I18N : CL_I18N

export const useByCountry = (chile: any, bolivia: any) => (isBolivia ? bolivia : chile)

export const validatePhoneNumber = (value) => isPhoneNumber(value.replace(' ', ''), isChile ? 'CL' : 'BO')
