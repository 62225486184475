import React, { useMemo } from 'react'

import Icon, { IconProps } from '../atoms/Icon'

export enum AlertColors {
  RED = 'red',
  BLUE = 'blue',
  YELLOW = 'yellow',
  DEFAULT = 'default',
}

interface InfoAlertProps {
  message: React.ReactNode
  iconId?: IconProps['id']
  color?: AlertColors
  iconColor?: string
}

const InfoAlert = ({ message, iconId, color = AlertColors.BLUE, iconColor }: InfoAlertProps) => {
  const bgColorContainer = useMemo(() => {
    switch (color) {
      case AlertColors.BLUE:
        return 'bg-[#E9F4FF]'
      case AlertColors.RED:
        return 'bg-[#FDE2E3]'
      case AlertColors.YELLOW:
        return 'bg-[#FEF8EC]'
      default:
        return 'bg-white'
    }
  }, [color])

  const bgColorBorder = useMemo(() => {
    switch (color) {
      case AlertColors.BLUE:
        return 'bg-blue'
      case AlertColors.RED:
        return 'bg-[#B41713]'
      case AlertColors.YELLOW:
        return 'bg-warning'
      default:
        return 'bg-black-med'
    }
  }, [color])

  const textColor = useMemo(() => {
    switch (color) {
      case AlertColors.RED:
        return 'text-[#BB2926]'
      default:
        return ''
    }
  }, [color])

  const renderAlert = useMemo(() => {
    return (
      <div className={`flex ${bgColorContainer} rounded`}>
        <div className={`w-1 min-w-1 ${bgColorBorder} h-auto rounded-l mr-3`} />
        <div className="py-2 flex items-center w-full pr-4">
          {iconId && (
            <div className={`pr-2`}>
              <Icon id={iconId} color={iconColor} size={16} />
            </div>
          )}
          <div className={`text-xs w-full ${textColor}`}>{message}</div>
        </div>
      </div>
    )
  }, [message])

  return renderAlert
}

export default InfoAlert
