import { createContext, Dispatch, SetStateAction } from 'react'

export type LoadingContextState = {
  isGlobalLoading: boolean
  setIsGlobalLoading: Dispatch<SetStateAction<boolean>>
}

export const LoadingContextInitialState: LoadingContextState = {
  isGlobalLoading: false,
  setIsGlobalLoading: () => {},
}

export const LoadingContext = createContext(LoadingContextInitialState)
