import React from 'react'

import { useByCountry } from '../../i18n'
import Icon, { IconProps } from '../atoms/Icon'

interface CustomNavbarProps {
  leftAction: () => void
  leftIcon: IconProps['id']
  title: string
  logo: any
  className?: string
}
const CustomNavbar = (props: CustomNavbarProps) => {
  const { leftAction, leftIcon, title, logo, className } = props
  return (
    <div
      className={`w-full flex flex-row bg-primary desktop:bg-white justify-center items-center h-16 min-h-16 shadow-md py-1 ${className}`}
    >
      <div className="h-full flex w-20 justify-center items-center">
        <Icon id={leftIcon} size={24} onClick={leftAction} className="cursor-pointer text-white desktop:text-black" />
      </div>
      <div className="h-full flex flex-1 justify-center items-center">
        <img src={logo} alt="logo" className={`${useByCountry('w-36', 'w-41')} desktop:hidden`} />
        <h3 className="font-bold text-md hidden desktop:block">{title}</h3>
      </div>
      <div className="h-full flex w-20 justify-center items-center" />
    </div>
  )
}

export default CustomNavbar
